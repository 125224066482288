function DocsSvg() {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="New-File--Streamline-Core.svg">
				<g id="new-file--empty-common-file-content">
					<path id="Vector" d="M17.2973 17.2974C17.2973 17.6493 17.1574 17.9868 16.9087 18.2356C16.6599 18.4843 16.3224 18.6242 15.9705 18.6242H4.02942C3.67753 18.6242 3.34006 18.4843 3.09124 18.2356C2.84243 17.9868 2.70264 17.6493 2.70264 17.2974V2.70276C2.70264 2.35087 2.84243 2.0134 3.09124 1.76458C3.34006 1.51576 3.67753 1.37598 4.02942 1.37598H12.6535L17.2973 6.01973V17.2974Z" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
					<path id="Vector 2529" d="M11.9902 1.37598V6.68312H17.2974" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				</g>
			</g>
		</svg>
	);
}

export default DocsSvg;