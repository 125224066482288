//react components
import React, { useContext } from "react";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//next.js componentes
import Link from "next/link";
//css library
import { css } from "@emotion/react";
import styled from "@emotion/styled";
//mui components
import { useTheme } from "@mui/material/styles";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MuiIconButton from "@mui/material/IconButton"
//sqlitecloud hooks
import { useGetActualProjectId } from "@custom-hooks/useApi";
//sqlitecloud components
import SidebarNavSection from "./SidebarNavSection";
import OpenDialog from "@components/GenericElements/OpenDialog";
import SingleProjectInfo from "@components/Projects/SingleProjectInfo";
import HamburgerNavSVG from "@components/GenericElements/SVG/HamburgerNavSVG";
import CloseNavSVG from "@components/GenericElements/SVG/CloseNavSVG";
import Menu from "@components/navbar/Menu";
import Docs from "@components/navbar/Docs";
import HelpDropdown from "@components/navbar/HelpDropdown";
import NavbarUserDropdown from "@components/navbar/NavbarUserDropdown";
import Avatar from "@components/GenericElements/Avatar";

const IconButton = styled(MuiIconButton)`

`;



const baseScrollbar = (props) => css`
  background-color:${props.theme.palette.primary.white};
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
	${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;


const ProjectAddressButton = ({ isMobile, callback }) => {
	//get theme
	const theme = useTheme();
	return (
		<Box sx={{
			position: "sticky",
			bottom: 0,
			width: "100%",
			padding: isMobile ? "1rem 0 1.625rem" : "1rem 0 2.375rem",
			display: "flex",
			justifyContent: "center",
			background: "white"
		}}>
			<Button
				variant="simple-text"
				onClick={callback}
				sx={{
					margin: "0 auto",
					padding: "0.75rem 0.125rem",
					width: "13.375rem",
					height: "2.875rem",
					backgroundColor: theme.palette.neutral.pale,
					borderRadius: "0.5rem",
					border: `1px ${theme.palette.primary.main} solid`,
					wordWrap: "break-word",
				}}
			>
				<Typography fontFamily={"SwissIntl"} variant="12px-demi">Show connection strings</Typography>
			</Button >
		</Box>
	)
}

const SidebarNav = ({ items, show, isProjectsHome, open, onDrawerToggle, isMobile, hideSideBarOnDesk, handleHideSideBarOnDesk, customPageSidebar }) => {
	//get actual project id
	const projectId = useGetActualProjectId();
	//get theme
	const theme = useTheme();
	//get user info
	const { userInfo } = useContext(StateContext);
	//iterate on all projects to search actual selected project
	//and build navigation tree for the selected project
	if (projectId) {
		if (items) {
			items[0].pages.forEach((project) => {
				if (project.projectId === projectId) {
					items = [{}];
					items[0].title = project.title;
					items[0].pages = project.children;
				}
			});
		}
	}
	return (
		<>
			<Box>
				<Box sx={{
					padding: "2.5rem 0.5rem 2rem",
					position: "sticky",
					top: 0,
					zIndex: 50,
					background: "white",
					marginBottom: isMobile? "10px" : undefined,
				}}>
					<Grid container flexDirection={hideSideBarOnDesk ? "column" : "row"} justifyContent={hideSideBarOnDesk ? "flex-start" : "space-between"} alignItems={"start"} flexWrap={"nowrap"} rowGap={2}>
						<Grid item>
							<Link href="/" passHref>
								<a className="unstyled-link">
									<img
										style={{
											width: "2.75rem",
											background: "transparent",
											cursor: "pointer"
										}}
										alt="SQlite Cloud"
										src="/static/img/logo/logo-light@4x.png"
									/>
								</a>
							</Link>
						</Grid>
						{
							isMobile &&
							<Grid item>
								<Menu open={open} isMobile={isMobile} onDrawerToggle={onDrawerToggle} isProjectsHome={isProjectsHome} />
							</Grid>
						}
						{
							!isMobile && false && //remove false to show enable sidebar on desktop
							<>
								{
									hideSideBarOnDesk &&
									<IconButton
										color="inherit"
										onClick={() => { handleHideSideBarOnDesk(false) }}
										size="large"
										sx={{ margin: 0, padding: 0 }}
										disableRipple={true}

									>
										<HamburgerNavSVG />
									</IconButton>
								}
								{
									!hideSideBarOnDesk &&
									<IconButton
										color="inherit"
										onClick={() => { handleHideSideBarOnDesk(true) }}
										size="large"
										sx={{ margin: 0, padding: 0 }}
										disableRipple={true}
									>
										<CloseNavSVG />
									</IconButton>
								}
							</>
						}
					</Grid>
				</Box>
				{
					isMobile &&
					<Box marginBottom={"2rem"} marginLeft={"0.5rem"} flexDirection={"column"} justifyContent={'flex-start'} alignItems={"flex-start"} gap={"1.5rem"} display={"inline-flex"}>
						<Box justifyContent={"flex-start"} alignItems={"center"} gap={"0.75rem"} display={"inline-flex"}>
							<Box width={"2.5rem"} height={"2.5rem"} justifyContent={"center"} alignItems={"center"} display={"flex"}>
								<Avatar width="2.5rem" height="2.5rem" name={userInfo.first_name + " " + userInfo.last_name}/>
							</Box>
							<Box width={"7.188rem"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"} display={"inline-flex"}>
								<Typography variant={"12px-demi"}>{userInfo.first_name + " " + userInfo.last_name}</Typography>
								<Typography variant={"12px-reg"}>{userInfo.email}</Typography>
							</Box>
						</Box>
					</Box>
				}
				{
					!hideSideBarOnDesk &&
					<List disablePadding sx={{ visibility: show ? "visible" : "hidden" }}>
						{
							!customPageSidebar &&
							<>
								{items &&
									items.map((item) => {
										return (
											<SidebarNavSection
												isProjectsHome={isProjectsHome}
												component="div"
												hideSideBarOnDesk={hideSideBarOnDesk}
												key={item.title}
												pages={item.pages}
												title={item.title}
												isMobile={isMobile}
											/>
										)
									})
								}
							</>
						}
						{
							customPageSidebar &&
							customPageSidebar
						}
					</List>
				}
				{
					isMobile &&
					<>
						<Box marginTop={"1rem"} marginBottom={"1rem"} height={0} border={`1px ${theme.palette.neutral.lightGrey} solid`} />
						<Box flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"} gap={"1rem"} display={"flex"} >
							<Typography color={theme.palette.secondary.accent3} variant="12px-reg" fontWeight={"450"}>SUPPORT</Typography>
							<Box flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"} display={"flex"}>
								<Docs isMobile={isMobile} />
								<HelpDropdown isMobile={isMobile} />
							</Box>
						</Box>
						<Box marginTop={"1rem"} marginBottom={"1rem"} height={0} border={`1px ${theme.palette.neutral.lightGrey} solid`} />
						<Box flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"} gap={"1rem"} display={"flex"} >
							<Typography color={theme.palette.secondary.accent3} variant="12px-reg" fontWeight={"450"}>USER SETTING</Typography>
							<Box flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"} display={"flex"}>
								<NavbarUserDropdown isMobile={isMobile} />
							</Box>
						</Box>
					</>
				}
			</Box >
			{
				!hideSideBarOnDesk && !isProjectsHome &&
				<OpenDialog actionComponent={<ProjectAddressButton isMobile={isMobile} />} size="small-modal" style="custom" decorationVariant={2}>
					<SingleProjectInfo />
				</OpenDialog >
			}
		</>
	);
};

export default SidebarNav;
