//react
import React from "react";
//mui components
import MuiAvatar from '@mui/material/Avatar';


const Avatar = ({ name, width = "2rem", height="2rem" }) => {
	function stringAvatar(name) {
		return {
			sx: {
				bgcolor: "#F0F5FD",
				color: "#0D0C22",
				fontSize: "1rem"
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
		};
	}
	return (
		<MuiAvatar
			sx={{ width: width, height: height }}
			alt={name}
			// src="/static/images/avatar/1.jpg" 
			{...stringAvatar(name)}
		/>
	);
}

export default Avatar;
