//react
import React, { useState, useEffect } from "react";
//css library
import styled from "@emotion/styled";
//mui components
import { useTheme } from "@mui/material/styles";
import { spacing } from "@mui/system";
//mui components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiTextField from "@mui/material/TextField";
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
`
//sqlitecloud components
import ModalTitle from "@generic-components/ModalTitle";
import ModalLoader from "@generic-components/ModalLoader";
import EditCreateModalButtons from "@generic-components/EditCreateModalButtons";
import DoubleActionModalButtons from "@generic-components/DoubleActionModalButtons";
import YesNoModalButtons from "@generic-components/YesNoModalButtons";
/**
 * actionType = "edit" | "confirm" | "strong-confirm" | "none"
 * @param {actionType} param0 
 * @returns 
 */
const ModalContent = ({ actionType = "none", ...props }) => {
	//get theme
	const theme = useTheme();
	//extract props
	const {
		children,
		title, subtitle, subtitleSmall, description,
		contact, contactText, feedbackFormContent,
		showLoader,
		editButtonLabel,
		firstButtonLabel, secondButtonLabel,
		firstButtonAction, secondButtonAction,
		firstButtonDisable, secondButtonDisable,
		disabled, disableYesExternalCondition = false,
		runCallback,
		hideConfirmWarning = false, confirmMessage = "This action is permanent and cannot be undone.", noCallback, yesCallback,
		resourceType = "resource",
		confirmString = "xxxxx",
		verifyString = "xxxxx",
	} = props;
	//define row gap based on action type
	let innerRowGap = "1.125rem";
	switch (actionType) {
		case "edit":
			innerRowGap = "1.125rem";
			break;
		case "confirm":
			innerRowGap = "0.75rem";
			break;
		default:
			break;
	}
	//define strong-confirm label
	const ConfirmLabel = () => {
		return (
			<Typography variant="13px-light">
				Enter the {resourceType} name <span style={{ display: "inline", fontWeight: 600 }}>{confirmString}</span> to continue:
			</Typography>
		)
	}
	const VerifyLabel = () => {
		return (
			<Typography variant="13px-light">
				To verify, type <span style={{ display: "inline", fontWeight: 600 }}>{verifyString}</span> below:
			</Typography>
		)
	}
	//handle strong confirm
	const [missingStrongConfirm, setMissingStrongConfirm] = useState(false);
	const [confirText, setConfirmText] = useState("");
	const [verifyText, setVerifyText] = useState("");
	useEffect(() => {
		if (actionType === "strong-confirm") {
			if (
				confirText && confirText === confirmString &&
				verifyText && verifyText === verifyString
			) {
				setMissingStrongConfirm(false);
			} else {
				setMissingStrongConfirm(true);
			}
		} else {
			setMissingStrongConfirm(false);
		}
	}, [actionType, confirText, verifyText])
	//render
	return (
		<Grid container pt={2} flexDirection={"column"} rowGap={"2.25rem"} height={"100%"} flexWrap={"nowrap"}>
			<Grid item height={"100%"}>
				<Grid container height={"100%"} flexDirection={"column"} rowGap={innerRowGap} flexWrap={"nowrap"}>
					<ModalTitle
						title={title}
						subtitle={subtitle}
						subtitleSmall={subtitleSmall}
						description={description}
						contact={contact}
						contactText={contactText}
						feedbackFormContent={feedbackFormContent}
					/>
					<ModalLoader showLoader={showLoader} />
					{
						(actionType !== "confirm" && actionType !== "strong-confirm") &&
						<>
							{children}
						</>
					}

					{
						(actionType === "confirm" || actionType === "strong-confirm") && !showLoader &&
						<Grid item>
							<Grid container flexDirection={"column"} rowGap={"1.75rem"}>
								{
									!hideConfirmWarning &&
									<Typography variant="13px-light">
										{confirmMessage}
									</Typography>
								}
								{
									(actionType === "confirm" || actionType === "strong-confirm") &&
									<>
										{children}
									</>
								}
								{
									actionType === "strong-confirm" &&
									<Grid item maxWidth={"100%"}>
										<Grid container flexDirection={"column"} rowGap={"1.75rem"}>
											<TextField
												autoFocus
												label={<ConfirmLabel />}
												sx={{
													'& .MuiInputLabel-root': {
														wordBreak: "break-word",
														whiteSpace: "unset",
														overflow: "unset",
														textOverflow: "unset",
													}
												}}

												type="text"
												fullWidth
												value={confirText}
												onChange={(event) => {
													setConfirmText(event.target.value);
												}}
											/>
											<TextField
												label={<VerifyLabel />}
												type="text"
												fullWidth
												value={verifyText}
												onChange={(event) => {
													setVerifyText(event.target.value);
												}}
											/>
										</Grid>
									</Grid>
								}
							</Grid>
						</Grid>
					}
					{
						actionType === "none" &&
						<Grid item />
					}
				</Grid>
			</Grid>
			{
				actionType === "double-action" &&
				<DoubleActionModalButtons firstButtonLabel={firstButtonLabel} secondButtonLabel={secondButtonLabel} firstButtonDisable={firstButtonDisable ? true : false} secondButtonDisable={secondButtonDisable ? true : false} firstButtonAction={firstButtonAction} secondButtonAction={secondButtonAction} />
			}
			{
				actionType === "edit" &&
				<EditCreateModalButtons label={editButtonLabel} disabled={disabled ? true : false} run={runCallback} />
			}
			{
				(actionType === "confirm" || actionType === "strong-confirm") &&
				<YesNoModalButtons disabled={disabled ? true : false} disabledYes={missingStrongConfirm || disableYesExternalCondition} callback={noCallback} yesAction={yesCallback} />
			}
		</Grid>
	);
}

export default ModalContent;
