//react
import React, { useContext, useRef } from "react";
//style library
import styled from "@emotion/styled";
//mui components
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MuiIconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud components
import OpenDialog from "@generic-components/OpenDialog";
import JobsDropDown from "@components/Jobs/JobsDropDown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import HelpDropdown from "./HelpDropdown";
import UpgradingBanner from "@components/Banners/UpgradingBanner";
import Docs from "./Docs"
import HamburgerNavSVG from "@components/GenericElements/SVG/HamburgerNavSVG";
import CloseNavSVG from "@components/GenericElements/SVG/CloseNavSVG";
import { Search } from "@components/Search/Search";
//env variables
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

//item used to trigger banner dialog
const BannerItem = ({ callback }) => {
	return (
		<Button
			onClick={callback}
			sx={{ mr: 2 }}
			variant="simple-text-warning"
		>
			Upgrading Infrastructure
		</Button>
	)
}
const BannerDialog = () => {
	return (
		<OpenDialog
			size="medium-modal"
			style="custom"
			decorationVariant={0}
			actionComponent={<BannerItem />}
		>
			<Box>
				<UpgradingBanner />
			</Box>
		</OpenDialog>
	)
}

const IconButton = styled(MuiIconButton)`

`;

const Menu = ({ open, isMobile, onDrawerToggle, isProjectsHome }) => {
	//get theme
	const theme = useTheme();
	//enable banner
	const enableBanner = true;
	//get simulation generic user from context
	const { simulateGenericUser, setSimulateGenericUser } = useContext(StateContext);
	//render
	return (
		<Grid container flexDirection={"row"} justifyContent={"center"} alignItems={"center"} columnGap={4}>
			<Grid item>
				{
					UPGRADING_INFRA && UPGRADING_INFRA.toLocaleLowerCase() === "true" &&
					<BannerDialog />
				}
			</Grid>
			<Grid item>
				{
					!simulateGenericUser &&
					<Button
						variant="simple-text"
						sx={{ pointerEvents: "none", background: theme.palette.error.light, color: "white" }}
					>
						Experimental
					</Button>
				}
			</Grid>
			<Grid item>
				<Button
					onClick={() => { window.open("https://sqlitecloud.io/changelog", "_blank") }}
					variant="simple-text"
				>
					Beta version
				</Button>
			</Grid>
			{
				!isMobile &&
				<Search
					style="only-icon"
					targetLink="_blank"
					relativeUrl={false}
					hiddenClass="opacity-0"
					searchSource="doc"
					modalInputPlaceholder="Search SQLite Cloud"
				/>
			}
			{
				!isMobile &&
				<Grid item>
					<Docs />
				</Grid>
			}
			{
				isMobile && !open &&
				<Search
					style="only-icon"
					targetLink="_blank"
					relativeUrl={false}
					hiddenClass="opacity-0"
					searchSource="doc"
					modalInputPlaceholder="Search SQLite Cloud"
				/>
			}
			{
				!isProjectsHome &&
				<Grid item>
					<JobsDropDown />
				</Grid>
			}
			{
				!isMobile &&
				<Grid item>
					<HelpDropdown />
				</Grid>
			}
			{
				!isMobile &&
				<Grid item>
					<NavbarUserDropdown />
				</Grid>
			}
			{
				isMobile && !open &&
				<Grid item>
					{
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={onDrawerToggle}
							size="large"
							sx={{ margin: 0, padding: 0 }}
							disableRipple={true}
						>
							<HamburgerNavSVG />
						</IconButton>
					}
				</Grid>
			}
			{
				isMobile && open &&
				<Grid item>
					{
						<IconButton
							color="inherit"
							aria-label="Close drawer"
							onClick={onDrawerToggle}
							size="large"
							sx={{ margin: 0, padding: 0 }}
							disableRipple={true}
						>
							<CloseNavSVG />
						</IconButton>
					}
				</Grid>
			}
		</Grid>
	);
};

export default Menu;
