//react component
import React, { Fragment, useState, useContext } from "react";
//posthog
import { usePostHog } from 'posthog-js/react';
//formbricks
import formbricks from "@formbricks/js";
//mui components
import Tooltip from '@mui/material/Tooltip';
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//sqlitecloud lib
import { fetchApiRoute } from "@lib/utils";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud components
import OpenDialog from "@generic-components/OpenDialog";
import Avatar from "@components/GenericElements/Avatar";
import Profile from "@components/User/Profile";
//sqlitecloud libs
import {
	Profile as ProfileSVG,
	SignOut
} from "@lib/icons";

function NavbarUserDropdown({ isMobile = false }) {
	//get simulation generic user from context
	const { userInfo } = useContext(StateContext);
	//handle dropdown menu opening
	const [anchorMenu, setAnchorMenu] = useState(null);
	const toggleMenu = (event) => {
		setAnchorMenu(event.currentTarget);
	};
	const closeMenu = () => {
		setAnchorMenu(null);
	};
	//get posthog obj
	const posthog = usePostHog();
	//handle sign out
	const handleSignOut = async () => {
		try {
			const opt = {
				method: "GET",
				endpoint: "/api/logout",
				endpointCallLocation: "NavbarUserDropdown.js"
			}
			await fetchApiRoute(opt);
			//after succesfull log-out reset posthog
			posthog.reset();
			//after succesfull log-out reset formbricks
			try {
				formbricks.logout();
			} catch (error) {
				console.log(error);
			}

			//redirect to sign-in page
			window.location.href = "/auth/sign-in";
		} catch (error) {
			console.log(error);
		}
	};
	//define profile menu item 
	const ProfileMenuItem = ({ callback }) => {
		return (
			<MenuItem onClick={callback}>
				Profile
			</MenuItem>
		)
	}
	const ProfileMobile = ({ callback }) => {
		return (
			<IconButton
					sx={{ padding: 0 }}
					onClick={callback}
					color="primary"
					disableFocusRipple={true}
					disableRipple={true}
				>
					<Box padding={"0.75rem 1.75rem 0.75rem 1rem"} justifyContent={"flex-start"} alignItems={"center"} gap={"0.5rem"} display={"inline-flex"}>
						<ProfileSVG />
						<Typography fontFamily={"SwissIntl"} variant="14px-reg" fontWeight={"450"}>Profile</Typography>
					</Box>
				</IconButton>
		)
	}
	if (isMobile) {
		return (
			<>
				<OpenDialog
					size="small-modal"
					style="custom"
					actionOnClose={closeMenu}
					actionComponent={<ProfileMobile />}
				>
					<Profile />
				</OpenDialog>
				<IconButton
					sx={{ padding: 0 }}
					onClick={handleSignOut}
					color="primary"
					disableFocusRipple={true}
					disableRipple={true}
				>
					<Box padding={"0.75rem 1.75rem 0.75rem 1rem"} justifyContent={"flex-start"} alignItems={"center"} gap={"0.5rem"} display={"inline-flex"}>
						<SignOut />
						<Typography fontFamily={"SwissIntl"} variant="14px-reg" fontWeight={"450"}>Sign out</Typography>
					</Box>
				</IconButton>
			</>
		);
	}
	return (
		<Fragment>
			<Tooltip title={"User settings"}>
				<span>
					<IconButton
						sx={{ padding: 0 }}
						aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
						aria-haspopup="true"
						onClick={toggleMenu}
						color="primary"
						disableFocusRipple={true}
						disableRipple={true}
					>
						<Avatar name={userInfo.first_name + " " + userInfo.last_name} />
					</IconButton>
				</span>
			</Tooltip>
			<Menu
				id="menu-appbar"
				anchorEl={anchorMenu}
				open={Boolean(anchorMenu)}
				onClose={closeMenu}
			>
				<OpenDialog
					size="small-modal"
					style="custom"
					actionOnClose={closeMenu}
					actionComponent={<ProfileMenuItem />}
				>
					<Profile />
				</OpenDialog>
				<MenuItem onClick={handleSignOut}>
					Sign out
				</MenuItem>
			</Menu>
		</Fragment>
	);
}

export default NavbarUserDropdown;
