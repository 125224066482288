//react
import React, { useState } from "react";
//css library
import styled from "@emotion/styled";
//mui components
import { spacing } from "@mui/system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiCircularProgress from "@mui/material/CircularProgress";
//mira pro components
const CircularProgress = styled(MuiCircularProgress)(spacing);
//sqlitecloud
import { useGetActualProjectId, useEditData } from "@custom-hooks/useApi";

function ArchiveJob({ jobId, setError }) {
	//get actual project id
	const projectId = useGetActualProjectId();
	//get method to call editing api
	const editDataOpt = {
	};
	const { loading, editedData, error, editData } = useEditData(editDataOpt);
	//method call to archive job
	const archive = async (e) => {
		e.preventDefault();
		const opt = {
			method: "GET",
			endpoint: `/api/projects/${projectId}/jobs/${jobId}/archive`,
			endpointCallLocation: "ArchiveJob.js",
			mutateApis: [
				[`/api/projects/${projectId}/jobs/nodes`, "useGetJobNodes"]
			]
		}
		await editData(opt);
	};
	//render condition
	const showLoader = loading;
	const showJob = !error && !editedData;
	//render
	return (
		<Box sx={{
			display: "inline-block"
		}}
			mr={2}
		>
			{
				loading &&
				<CircularProgress size={20} />
			}
			{
				showJob && ! loading &&
				<Button
					disabled={loading}
					onClick={archive}
					color="secondary"
					mr={2}
					size="small"
				>
					Archive
				</Button>
			}
		</Box>
	);
}


export default ArchiveJob;
