//react components
import React from "react";

const ModaleDecoration = ({ variant = 1 }) => {
	return (
		<>
			{
				variant === 0 &&
				<></>
			}
			{
				variant === 1 &&
				<svg style={{
					zIndex: 1,
					position: "absolute",
					bottom: "0",
					left: "0",
					width: "100%",
					overflow: "hidden",
					pointerEvents: "none"
				}}
					width="461" height="265" viewBox="0 0 461 265" fill="none" xmlns="http://www.w3.org/2000/svg">
					<mask id="mask0_250_6298" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="-276" y="222" width="496" height="157">
						<path d="M116.454 378.597C171.654 351.317 245.759 293.523 209.5 281C185.5 252 155.535 244.209 155.535 244.209C155.535 244.209 65.8734 212.761 -6.96436 224.709C-79.8021 236.657 -128.74 298.088 -128.74 298.088C-233.465 293.709 -299.029 366.649 -268.465 378.597C-219.652 378.597 116.454 378.597 116.454 378.597Z" fill="#4A5154" />
					</mask>
					<g mask="url(#mask0_250_6298)">
						<line x1="8.84017" y1="162.369" x2="8.84015" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="18.5775" y1="162.369" x2="18.5775" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="28.9193" y1="162.369" x2="28.9193" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="38.6566" y1="162.369" x2="38.6566" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="48.3944" y1="162.369" x2="48.3944" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="58.1312" y1="162.369" x2="58.1312" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="67.8695" y1="162.369" x2="67.8694" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="77.8328" y1="162.369" x2="77.8328" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="87.7972" y1="162.369" x2="87.7972" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="97.7616" y1="162.369" x2="97.7615" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="107.726" y1="162.369" x2="107.726" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="118.014" y1="162.369" x2="118.014" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="127.752" y1="162.369" x2="127.752" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="137.49" y1="162.369" x2="137.49" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="147.227" y1="162.369" x2="147.227" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="156.964" y1="162.369" x2="156.964" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="166.701" y1="162.37" x2="166.701" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="176.439" y1="162.369" x2="176.439" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="186.177" y1="162.369" x2="186.177" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="195.914" y1="162.369" x2="195.914" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="205.651" y1="162.369" x2="205.651" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="215.389" y1="162.37" x2="215.388" y2="382.775" stroke="#6BA2EA" strokeWidth="0.89127" />
					</g>
					<mask id="mask1_250_6298" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="62" y="223" width="471" height="157">
						<path d="M455.453 379.597C510.653 352.317 555.793 286.232 519.534 273.709C482.606 251.645 494.534 245.209 494.534 245.209C494.534 245.209 404.872 213.761 332.035 225.709C259.197 237.657 210.259 299.088 210.259 299.088C105.534 294.709 39.9697 367.649 70.5345 379.597C119.347 379.597 455.453 379.597 455.453 379.597Z" fill="#4A5154" />
					</mask>
					<g mask="url(#mask1_250_6298)">
						<line x1="94.4823" y1="163.37" x2="94.4822" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="104.22" y1="163.369" x2="104.22" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="113.958" y1="163.369" x2="113.958" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="123.695" y1="163.369" x2="123.695" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="133.432" y1="163.369" x2="133.432" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="143.169" y1="163.369" x2="143.169" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="152.907" y1="163.369" x2="152.907" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="162.645" y1="163.369" x2="162.645" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="172.382" y1="163.369" x2="172.382" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="182.119" y1="163.369" x2="182.119" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="191.856" y1="163.369" x2="191.856" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="201.594" y1="163.369" x2="201.594" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="211.332" y1="163.369" x2="211.332" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="221.069" y1="163.369" x2="221.069" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="230.806" y1="163.369" x2="230.806" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="240.544" y1="163.369" x2="240.544" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="250.598" y1="163.369" x2="250.598" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="260.27" y1="163.369" x2="260.27" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="269.942" y1="163.369" x2="269.942" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="279.614" y1="163.369" x2="279.614" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="289.285" y1="163.369" x2="289.285" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="299.152" y1="163.369" x2="299.152" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="308.889" y1="163.369" x2="308.889" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="318.627" y1="163.369" x2="318.627" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="328.364" y1="163.369" x2="328.364" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="338.101" y1="163.369" x2="338.101" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="347.839" y1="163.369" x2="347.839" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="357.576" y1="163.369" x2="357.576" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="367.918" y1="163.369" x2="367.918" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="377.656" y1="163.369" x2="377.656" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="387.393" y1="163.369" x2="387.393" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="397.13" y1="163.369" x2="397.13" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="406.868" y1="163.369" x2="406.868" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="416.832" y1="163.369" x2="416.832" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="426.796" y1="163.369" x2="426.796" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="436.761" y1="163.369" x2="436.761" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="446.725" y1="163.369" x2="446.725" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="457.014" y1="163.369" x2="457.013" y2="383.775" stroke="#6BA2EA" strokeWidth="0.89127" />
					</g>
					<path fillRule="evenodd" clipRule="evenodd" d="M-569.914 744.504C-622.201 636.845 -681.405 498.662 -615.868 391.522C-551.175 285.762 -403.941 332.674 -293.598 292.784C-198.237 258.31 -119.271 159.254 -23.0443 177.212C88.4913 198.027 206.591 271.057 229.077 390.326C250.863 505.872 134.194 597.58 76.1223 703.183C28.2035 790.324 7.60363 900.381 -76.2754 945.615C-164.289 993.079 -264.351 970.045 -352.291 934.218C-443.893 896.899 -524.739 837.517 -569.914 744.504Z" fill="#F0F5FD" />
					<path fillRule="evenodd" clipRule="evenodd" d="M-11.4046 740.801C-63.6921 633.143 -122.896 494.959 -57.3586 387.819C7.33451 282.06 154.568 328.971 264.911 289.081C360.272 254.607 439.238 155.552 535.465 173.509C647.001 194.324 765.1 267.354 787.587 386.623C809.372 502.169 692.703 593.877 634.632 699.48C586.713 786.622 566.113 896.678 482.234 941.912C394.22 989.376 294.159 966.342 206.218 930.515C114.617 893.196 33.7702 833.814 -11.4046 740.801Z" fill="#F0F5FD" />
				</svg>
			}
			{
				variant === 2 &&
				<svg
					style={{
						zIndex: 1,
						position: "absolute",
						bottom: "0",
						left: "0",
						width: "100%",
						overflow: "hidden",
						pointerEvents: "none"
					}}
					width="486" height="251" viewBox="0 0 486 251" fill="none" xmlns="http://www.w3.org/2000/svg">
					<mask id="mask0_394_13233" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="61" y="222" width="471" height="158">
						<path d="M454.453 379.3C509.653 352.02 554.793 285.935 518.534 273.412C481.606 251.348 493.534 244.912 493.534 244.912C493.534 244.912 403.872 213.464 331.035 225.412C258.197 237.36 209.259 298.791 209.259 298.791C104.534 294.412 38.9697 367.352 69.5345 379.3C118.347 379.3 454.453 379.3 454.453 379.3Z" fill="#4A5154" />
					</mask>
					<g mask="url(#mask0_394_13233)">
						<line x1="93.482" y1="163.073" x2="93.482" y2="383.479" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="103.22" y1="163.072" x2="103.22" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="112.958" y1="163.072" x2="112.958" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="122.695" y1="163.072" x2="122.695" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="132.432" y1="163.072" x2="132.432" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="142.169" y1="163.072" x2="142.169" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="151.907" y1="163.072" x2="151.907" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="161.645" y1="163.072" x2="161.645" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="171.382" y1="163.072" x2="171.382" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="181.119" y1="163.072" x2="181.119" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="190.856" y1="163.072" x2="190.856" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="200.594" y1="163.072" x2="200.594" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="210.332" y1="163.072" x2="210.332" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="220.069" y1="163.072" x2="220.069" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="229.806" y1="163.072" x2="229.806" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="239.544" y1="163.072" x2="239.544" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="249.598" y1="163.072" x2="249.598" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="259.27" y1="163.072" x2="259.27" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="268.942" y1="163.072" x2="268.942" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="278.614" y1="163.072" x2="278.614" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="288.285" y1="163.072" x2="288.285" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="298.152" y1="163.072" x2="298.152" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="307.889" y1="163.072" x2="307.889" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="317.627" y1="163.072" x2="317.627" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="327.364" y1="163.072" x2="327.364" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="337.101" y1="163.072" x2="337.101" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="346.839" y1="163.072" x2="346.839" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="356.576" y1="163.072" x2="356.576" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="366.918" y1="163.072" x2="366.918" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="376.655" y1="163.072" x2="376.655" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="386.393" y1="163.072" x2="386.393" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="396.13" y1="163.072" x2="396.13" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="405.868" y1="163.072" x2="405.868" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="415.831" y1="163.072" x2="415.831" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="425.796" y1="163.072" x2="425.796" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="435.76" y1="163.072" x2="435.76" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="445.725" y1="163.072" x2="445.725" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="456.013" y1="163.072" x2="456.013" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="465.751" y1="163.072" x2="465.751" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="475.488" y1="163.072" x2="475.488" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
						<line x1="485.225" y1="163.072" x2="485.225" y2="383.478" stroke="#6BA2EA" strokeWidth="0.89127" />
					</g>
					<path fillRule="evenodd" clipRule="evenodd" d="M-570.914 744.206C-623.201 636.547 -682.405 498.364 -616.868 391.224C-552.175 285.464 -404.941 332.375 -294.598 292.486C-199.237 258.012 -120.271 158.956 -24.0441 176.914C87.4915 197.729 205.591 270.759 228.078 390.027C249.863 505.574 133.194 597.281 75.1226 702.885C27.2038 790.026 6.60387 900.083 -77.2751 945.317C-165.289 992.781 -265.35 969.747 -353.291 933.92C-444.892 896.601 -525.739 837.219 -570.914 744.206Z" fill="#F0F5FD" />
					<path fillRule="evenodd" clipRule="evenodd" d="M-12.4045 740.503C-64.6921 632.844 -123.896 494.661 -58.3585 387.521C6.33451 281.761 153.568 328.672 263.911 288.783C359.272 254.309 438.238 155.253 534.465 173.211C646.001 194.026 764.1 267.056 786.587 386.324C808.372 501.87 691.703 593.578 633.632 699.182C585.713 786.323 565.113 896.38 481.234 941.614C393.22 989.078 293.159 966.044 205.218 930.217C113.617 892.898 32.7702 833.516 -12.4045 740.503Z" fill="#F0F5FD" />
				</svg>
			}
		</>
	);
};

export default ModaleDecoration;

