//react
import React, { useContext } from "react";
//MIRA pro componente
import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

const reduceChildRoutes = (props) => {
	const { items, page, depth, currentRoute, query, isProjectsHome, isMobile, hideSideBarOnDesk } = props; //Added by SQLite
	if (page) {
		if (page.children) {
			let open = false; //Added by SQLite 
			// //if the page project id the url query correspond to the project id in the sidebar object set open to true
			// if (depth === 0 && query.projectId === page.projectId) {
			// 	open = true;
			// }
			// if ((page.title === "Security" || page.title === "Console" || page.title === "Advanced") && query.projectId === page.projectId) {
			// 	open = true;
			// }
			open = currentRoute.includes(page.slug);

			items.push(
				<SidebarNavListItem
					depth={depth}
					icon={page.icon}
					key={page.title}
					badge={page.badge}
					open={open}
					title={page.title}
					href={page.href}
					slug={page.slug}
					projectIndex={page.projectIndex} //Added by SQLite
					experimental={page.experimental} //Added by SQLite
					isProjectsHome={isProjectsHome} //Added by SQLite
					isMobile={isMobile} //Added by SQLite
					hideSideBarOnDesk={hideSideBarOnDesk} //Added by SQLite
				>
					<SidebarNavList depth={depth + 1} pages={page.children} isProjectsHome={isProjectsHome} hideSideBarOnDesk={hideSideBarOnDesk}/>
				</SidebarNavListItem>
			);
		} else {
			items.push(
				<SidebarNavListItem
					depth={depth}
					projectIndex={page.projectIndex} //Added by SQLite
					experimental={page.experimental} //Added by SQLite
					isMobile={isMobile} //Added by SQLite
					href={page.href}
					slug={page.slug}
					icon={page.icon}
					key={page.title}
					badge={page.badge}
					title={page.title}
					isProjectsHome={isProjectsHome}
					hideSideBarOnDesk={hideSideBarOnDesk}
				/>
			);
		}
	}

	return items;
};

export default reduceChildRoutes;
