//react
import React from "react";
//mui components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const EditCreateModalButtons = ({ label, disabled = false, run }) => {
	//render
	return (
		<Grid item>
			<Grid container width={"100%"} justifyContent={"center"} columnSpacing={2}>
				<Grid item>
					<Button disabled={disabled} variant="modal-primary" onClick={run} >
						{label}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}
export default EditCreateModalButtons;