//react
import { useEffect, useState, useRef } from "react";
//focus-trap-react
import FocusTrap from "focus-trap-react";
//utils
import { hasClass } from "@lib/utils";
//useDocsSearch
import { useDocsSearch } from "./useDocsSearch/index";


function SearchIcon(props) {
	return (
		<svg
			viewBox="0 0 17 17"
			{...props}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.14567 14.8748C11.8621 14.8748 14.8748 11.8621 14.8748 8.14567C14.8748 4.42925 11.8621 1.4165 8.14567 1.4165C4.42925 1.4165 1.4165 4.42925 1.4165 8.14567C1.4165 11.8621 4.42925 14.8748 8.14567 14.8748Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.5832 15.5832L14.1665 14.1665"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

function EmptyState(props) {
	return (
		<svg width="278" height="53" viewBox="0 0 278 53" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_3575_17455)">
				<path d="M135.195 51.6425C149.099 51.6425 160.37 40.3709 160.37 26.4667C160.37 12.5626 149.099 1.29102 135.195 1.29102C121.291 1.29102 110.019 12.5626 110.019 26.4667C110.019 40.3709 121.291 51.6425 135.195 51.6425Z" stroke="#2174E2" strokeWidth="0.844553" strokeMiterlimit="10" />
				<path d="M133.185 12.0391C130.624 12.0391 128.119 12.7987 125.989 14.222C123.859 15.6453 122.199 17.6683 121.218 20.0352C120.238 22.402 119.982 25.0064 120.481 27.519C120.981 30.0317 122.215 32.3397 124.026 34.1512C125.838 35.9627 128.146 37.1963 130.658 37.6961C133.171 38.1959 135.775 37.9394 138.142 36.959C140.509 35.9787 142.532 34.3184 143.955 32.1883C145.379 30.0582 146.138 27.5539 146.138 24.992C146.138 21.5568 144.773 18.2623 142.344 15.8331C139.915 13.404 136.621 12.0393 133.185 12.0391Z" fill="white" stroke="#2175E2" strokeWidth="0.98898" strokeMiterlimit="10" />
				<path d="M142.795 34.6025L149.278 41.0847" stroke="#2175E2" strokeWidth="0.98898" strokeMiterlimit="10" strokeLinecap="round" />
				<path d="M136.884 21.9423C136.884 23.3381 135.867 24.3372 134.895 24.9837C133.984 25.6008 133.452 25.9535 133.452 27.0701V27.7313H132.495V27.0701C132.495 25.6302 133.209 25.116 134.363 24.3078C135.123 23.7789 135.897 23.0883 135.897 21.9717C135.897 20.8256 134.895 20.2085 133.482 20.2085C131.645 20.2085 130.901 21.4133 130.901 22.6916H129.914C129.914 20.4436 131.584 19.4004 133.558 19.4004C135.335 19.4004 136.884 20.2085 136.884 21.9423ZM132.328 30.2438V28.8627H133.65V30.2438H132.328Z" fill="#2175E2" />
				<path d="M273.465 27.5777C259.331 27.5777 259.331 18.6406 245.192 18.6406C231.054 18.6406 231.054 27.5777 216.92 27.5777C202.781 27.5777 202.781 18.6406 188.647 18.6406C174.513 18.6406 174.509 27.5777 160.375 27.5777" stroke="#2174E2" strokeWidth="0.844553" strokeMiterlimit="10" />
				<path d="M110.024 27.5777C96.7433 27.5777 96.7433 18.6406 83.4628 18.6406C70.1824 18.6406 70.1824 27.5777 56.9019 27.5777C43.6214 27.5777 43.6214 18.6406 30.3364 18.6406C17.0559 18.6406 17.0559 27.5777 3.771 27.5777" stroke="#2174E2" strokeWidth="0.844553" strokeMiterlimit="10" />
				<path d="M217.135 29.5398C218.073 29.5398 218.837 28.7801 218.837 27.8373C218.837 26.8944 218.078 26.1348 217.135 26.1348C216.192 26.1348 215.432 26.8944 215.432 27.8373C215.432 28.7801 216.196 29.5398 217.135 29.5398Z" fill="#2174E2" />
				<path d="M188.867 20.5593C189.805 20.5593 190.569 19.7997 190.569 18.8568C190.569 17.9139 189.809 17.1543 188.867 17.1543C187.924 17.1543 187.164 17.9139 187.164 18.8568C187.164 19.7997 187.928 20.5593 188.867 20.5593Z" fill="#2174E2" />
				<path d="M84.6112 20.5593C85.5496 20.5593 86.3137 19.7997 86.3137 18.8568C86.3137 17.9139 85.5541 17.1543 84.6112 17.1543C83.6683 17.1543 82.9087 17.9139 82.9087 18.8568C82.9087 19.7997 83.6728 20.5593 84.6112 20.5593Z" fill="#2174E2" />
				<path d="M275.377 29.8318C276.316 29.8318 277.08 29.0721 277.08 28.1293C277.08 27.1864 276.32 26.4268 275.377 26.4268C274.434 26.4268 273.675 27.1864 273.675 28.1293C273.675 29.0721 274.439 29.8318 275.377 29.8318Z" stroke="#2174E2" strokeWidth="0.844553" strokeMiterlimit="10" />
				<path d="M56.7455 29.5369C57.6839 29.5369 58.448 28.7772 58.448 27.8343C58.448 26.8915 57.6883 26.1318 56.7455 26.1318C55.8026 26.1318 55.043 26.8915 55.043 27.8343C55.043 28.7772 55.8071 29.5369 56.7455 29.5369Z" fill="#2174E2" />
				<path d="M2.62243 29.1296C3.56083 29.1296 4.32495 28.37 4.32495 27.4271C4.32495 26.4843 3.56529 25.7246 2.62243 25.7246C1.67957 25.7246 0.919922 26.4843 0.919922 27.4271C0.919922 28.37 1.68404 29.1296 2.62243 29.1296Z" stroke="#2174E2" strokeWidth="0.844553" strokeMiterlimit="10" />
			</g>
			<defs>
				<clipPath id="clip0_3575_17455">
					<rect width="277" height="51.1915" fill="white" transform="translate(0.5 0.87207)" />
				</clipPath>
			</defs>
		</svg>
	);
}

function DbIcon(props) {
	return (
		<svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.125 3.625C13.125 4.66053 10.6066 5.5 7.5 5.5C4.3934 5.5 1.875 4.66053 1.875 3.625M13.125 3.625C13.125 2.58947 10.6066 1.75 7.5 1.75C4.3934 1.75 1.875 2.58947 1.875 3.625M13.125 3.625V12.375C13.125 13.4125 10.625 14.25 7.5 14.25C4.375 14.25 1.875 13.4125 1.875 12.375V3.625M13.125 8C13.125 9.0375 10.625 9.875 7.5 9.875C4.375 9.875 1.875 9.0375 1.875 8" stroke="#2175E2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>

	);
}

function PlatIcon(props) {
	return (
		<svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.5 11V12.5M5.25 13.25H9.75M3 11H12C12.8284 11 13.5 10.3284 13.5 9.5V4.25C13.5 3.42157 12.8284 2.75 12 2.75H3C2.17157 2.75 1.5 3.42157 1.5 4.25V9.5C1.5 10.3284 2.17157 11 3 11Z" stroke="#2175E2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}

function RefIcon(props) {
	return (
		<svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.8334 15H2.75766C2.33573 15 1.93106 14.8298 1.63271 14.5269C1.33437 14.224 1.16675 13.813 1.16675 13.3846C1.16675 12.9562 1.33437 12.5453 1.63271 12.2423C1.93106 11.9394 2.33573 11.7692 2.75766 11.7692H11.7728C12.0541 11.7692 12.3239 11.6557 12.5228 11.4538C12.7216 11.2519 12.8334 10.9779 12.8334 10.6923V2.07692C12.8334 1.7913 12.7216 1.51739 12.5228 1.31542C12.3239 1.11346 12.0541 1 11.7728 1H2.75766C2.34297 0.99985 1.94462 1.16412 1.64746 1.45781C1.35029 1.7515 1.17781 2.15139 1.16675 2.57231V13.3415" stroke="#2175E2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M11.7727 11.7686V14.9993" stroke="#2175E2" strokeWidth="1.15664" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}

function StarIcon(props) {
	return (
		<svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.80687 2.48258C7.04497 1.83914 7.95503 1.83914 8.19313 2.48258L9.37819 5.68516C9.45304 5.88746 9.61254 6.04695 9.81484 6.12181L13.0174 7.30687C13.6609 7.54497 13.6609 8.45503 13.0174 8.69313L9.81484 9.87819C9.61254 9.95304 9.45304 10.1125 9.37819 10.3148L8.19313 13.5174C7.95503 14.1609 7.04497 14.1609 6.80687 13.5174L5.62181 10.3148C5.54695 10.1125 5.38746 9.95304 5.18516 9.87819L1.98258 8.69313C1.33914 8.45503 1.33914 7.54497 1.98258 7.30687L5.18516 6.12181C5.38746 6.04695 5.54695 5.88746 5.62181 5.68516L6.80687 2.48258Z" stroke="#2175E2" strokeWidth="1.5" strokeLinejoin="round" />
		</svg>
	);
}

function SdkIcon(props) {
	return (
		<svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_3690_6558)">
				<path d="M11.8689 5.94531H13.1189" stroke="#2175E2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M9.9939 5.94531H10.3064" stroke="#2175E2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M9.9939 7.94531H13.1189" stroke="#2175E2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M8.5564 10.1328H13.1189" stroke="#2175E2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M4.8064 10.1328H6.2439" stroke="#2175E2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M4.83168 1.51563L5.08168 2.26563C5.14418 2.51563 5.45668 2.70313 5.70668 2.64063L6.45668 2.45313C7.14416 2.32813 7.58166 3.07813 7.14416 3.57813L6.58168 4.14063C6.39418 4.32813 6.39418 4.64063 6.58168 4.82813L7.14416 5.39063C7.58166 5.89063 7.14416 6.70313 6.45668 6.51563L5.70668 6.32813C5.45668 6.26563 5.14418 6.39063 5.08168 6.70313L4.83168 7.45313C4.64418 8.14063 3.70668 8.14063 3.51918 7.45313L3.26918 6.70313C3.20668 6.45313 2.89418 6.26563 2.64418 6.32813L1.89418 6.51563C1.20668 6.64063 0.769184 5.89063 1.20668 5.39063L1.76918 4.82813C1.95668 4.64063 1.95668 4.32813 1.76918 4.14063L1.20668 3.57813C0.769184 3.07813 1.20668 2.26563 1.89418 2.45313L2.64418 2.64063C2.89418 2.70313 3.20668 2.57813 3.26918 2.26563L3.51918 1.51563C3.70668 0.828125 4.64418 0.828125 4.83168 1.51563Z" stroke="#2175E2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M6.78125 12.4326L6.15625 14.9451" stroke="#2175E2" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M9.28125 12.4326L9.90625 14.9451" stroke="#2175E2" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M4.28125 14.9453H11.7812" stroke="#2175E2" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M9.86719 2.38281H13.8125C14.5 2.38281 15.0625 2.94813 15.0625 3.63906V11.1766C15.0625 11.8675 14.5 12.4328 13.8125 12.4328H2.25C1.5625 12.4328 1 11.8675 1 11.1766V8.82031" stroke="#2175E2" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M3.23804 4.48438C3.23804 4.73302 3.33681 4.97147 3.51262 5.14729C3.68844 5.3231 3.9269 5.42188 4.17554 5.42188C4.42418 5.42188 4.66263 5.3231 4.83845 5.14729C5.01427 4.97147 5.11304 4.73302 5.11304 4.48438C5.11304 4.23573 5.01427 3.99728 4.83845 3.82146C4.66263 3.64565 4.42418 3.54688 4.17554 3.54688C3.9269 3.54688 3.68844 3.64565 3.51262 3.82146C3.33681 3.99728 3.23804 4.23573 3.23804 4.48438Z" stroke="#2175E2" />
			</g>
			<defs>
				<clipPath id="clip0_3690_6558">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

function createMarkup(snippet, selected) {
	snippet = snippet.replaceAll(
		"<b>",
		`<span class="tw-relative tw-text-brand tw-font-medium">`,
	);
	snippet = snippet.replaceAll(
		"</b>",
		`</span>`,
	);
	return { __html: snippet };
}

export function SQLiteCloudSearch(props) {
	const { searchSource, targetLink, relativeUrl, sidebarId, hiddenClass, modalInputPlaceholder } = props;
	const searchRef = useRef(null);
	const searchListRef = useRef(null);
	//handle click on modal backgroud
	const handleClick = (event) => {
		if (event.target === event.currentTarget) {
			props.close(false);
			event.preventDefault();
		}
	};
	//handle keydown event
	const [selectedRes, setSelecectedRes] = useState(0);
	const handleKeyDown = (event) => {
		if (event.keyCode == 9 || event.keyCode == 16 || event.keyCode == 13) {
		} else if (event.keyCode == 38) {
			if (selectedRes > 0) {
				setSelecectedRes(selectedRes - 1);
				event.preventDefault();
				event.stopPropagation();
			}
		} else if (event.keyCode == 40) {
			if (selectedRes < searchRes.data.length - 1) {
				setSelecectedRes(selectedRes + 1);
				event.preventDefault();
				event.stopPropagation();
			}
		} else {
			searchRef.current.focus();
		}
	};
	//define search url
	const searchUrl = process.env.NEXT_PUBLIC_SEARCH;

	// Initialize the useSqlcSearch custom hook for docs search
	const {
		searchText,    // Text to search for
		searchRes,     // Search results
		searchResPrev, // Search prev results
		searchError,   // Error information if search fails
		validSearchUrl,// Boolean indicating if the search URL is valid
		isLoading,     // Boolean indicating if component is loading search result
		handleSearch,  // Function to handle search input changes
	} = useDocsSearch(searchUrl);

	//
	const [filteredSearchRes, setFilteredSearchRes] = useState({ data: [] });
	useEffect(() => {
		if (searchRes && searchRes.data) {
			const filteredSearchRes = { data: [] };
			filteredSearchRes.data = searchRes.data.filter((res) => (res.options && res.options.status && res.options.status === "publish"));
			setFilteredSearchRes(filteredSearchRes);
		}
	}, [searchRes])

	//set focus on input search after disabled
	useEffect(() => {
		document.body.style.overflow = "hidden";
		document.body.style.touchAction = "none";
		searchRef.current.focus();
		return () => {
			if (sidebarId) {
				const sidebarNavEl = document.getElementById(sidebarId);
				if (sidebarNavEl) {
					const hiddenState = hasClass(sidebarNavEl, hiddenClass);
					if (hiddenState) {
						document.body.style.overflowY = "auto";
						document.body.style.overflowX = "hidden";
						document.body.style.touchAction = "auto";
					}
				}
			} else {
				document.body.style.overflowY = "auto";
				document.body.style.overflowX = "hidden";
				document.body.style.touchAction = "auto";
			}
		};
	}, []);
	//set focus on selecte search result
	useEffect(() => {
		if (document.getElementById("search-res" + selectedRes)) {
			document.getElementById("search-res" + selectedRes).focus();
		}
	}, [selectedRes]);
	return (
		<FocusTrap>
			<div
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				className="tw-fixed tw-left-0 tw-top-0 tw-z-[20000] tw-flex tw-h-screen tw-w-screen tw-cursor-auto tw-flex-col tw-bg-black/60 tw-p-4 sm:tw-p-6 md:tw-p-[10vh] lg:tw-p-[12vh]"
				role="button"
				aria-expanded="true"
				aria-haspopup="listbox"
				aria-labelledby="search-label"
				tabIndex="0"
			>
				<div className="tw-mx-auto tw-flex tw-space-y-8 tw-min-h-0 tw-w-full tw-max-w-xl tw-flex-col tw-rounded-2xl tw-p-4 tw-bg-white tw-shadow-md">
					<header className="tw-relative tw-z-[1] tw-flex tw-flex-none tw-items-center tw-border tw-border-black tw-border-opacity-20 tw-h-10 tw-rounded-3xl">
						<div className="tw-w-full tw-px-4 tw-py-2">
							<div className="tw-relative tw-flex tw-items-center tw-py-2">
								<div className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex">
									<span className="tw-inline-flex tw-items-center">
										<SearchIcon className="tw-h-5 tw-w-5 tw-flex-none tw-stroke-brand" />
									</span>
								</div>
								<input
									ref={searchRef}
									type="text"
									name="search"
									id="search"
									placeholder={modalInputPlaceholder}
									value={searchText}
									onChange={handleSearch}
									autoComplete="off"
									tabIndex={0}
									className="tw-block tw-h-full tw-ml-6 tw-w-full w-border-0 tw-text-12px-reg tw-text-black tw-text-opacity-80 tw-ring-0 placeholder:tw-text-opacity-50 focus:tw-outline-none focus:tw-ring-0"
								/>
							</div>
						</div>
					</header>
					<div
						ref={searchListRef}
						className="tw-overflow-auto tw-bg-white tw-px-2"
					>
						{
							(!isLoading || searchResPrev.data.length === 0) && !searchError && searchText && filteredSearchRes.data && filteredSearchRes.data.length === 0 &&
							<div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-8">
								<p className="tw-text-16px-light tw-text-branddark">No search results found for "{searchText}"</p>
								<EmptyState />
							</div>
						}
						{
							!isLoading && searchError &&
							<div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-2">
								<p className="tw-text-16px-light tw-text-error">Check your query: {searchText}</p>
							</div>
						}
						{
							!searchError && filteredSearchRes.data && filteredSearchRes.data.length > 0 && (
								<h3 className="tw-text-12px-reg tw-text-black tw-mb-4">Results</h3>
							)
						}
						{
							!searchError && filteredSearchRes.data && filteredSearchRes.data.length > 0 &&
							<dl role="listbox" className="tw-divide-y tw-divide-transparent">
								{filteredSearchRes.data.map((res, i) => {
									//get status
									const status = res.options.status;
									//get category and define section name and section icon based on categoru
									const category = res.options.category;
									let section = "";
									let icon = null;
									switch (category) {
										case "blog":
											section = "Blog";
											icon = <StarIcon />;
											break;
										case "getting-started":
											section = "Getting Started";
											icon = <StarIcon />;
											break;
										case "database":
											section = "Database"
											icon = <DbIcon />;
											break;
										case "platform":
											icon = <PlatIcon />;
											break;
										case "reference":
											section = "Reference";
											icon = <RefIcon />;
											break;
										case "sdks":
											section = "SDKs";
											icon = <SdkIcon />;
											break;
										default:
											section = "Getting Started";
											icon = <StarIcon />;
											break;
									}
									//extract title of the page
									let title = res.options.title;
									//generate url of the page
									let url;
									if (relativeUrl) {
										const parsedUrl = new URL(window.location.href);
										let port = "";
										if (parsedUrl.hostname === "localhost") {
											port = "4321";
										}
										const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}${port ? `:${port}` : ""}`;
										const parsedPageUrl = new URL(res.url);
										const pageSlug = (parsedPageUrl.pathname.replace(/^\/|\/$/g, '')).toLocaleLowerCase();
										url = `${baseUrl}/${pageSlug}`;
									} else {
										url = res.url.toLocaleLowerCase();
									}
									//return search result
									return (
										<>
											{
												status === "publish" &&
												<dd
													key={i}
													role="option"
													aria-selected={selectedRes == i ? true : false}
													className="tw-my-2"
												>
													<div className={`tw-group tw-rounded-lg ${selectedRes == i ? "tw-border-2" : "tw-border"} border-solid ${selectedRes == i ? "tw-border-brand" : "tw-border-ice"}  tw-bg-cream hover:tw-bg-pale hover:tw-border-brand`}>
														<a
															target={targetLink}
															id={"search-res" + i}
															onFocus={() => {
																setSelecectedRes(i);
															}}
															href={url}
														// className={`group rounded-lg hover:bg-ice ${selectedRes == i ? "bg-brand hover:bg-brand/80" : "bg-transparent"}  block focus:outline-none focus:ring-0`}
														>
															<div className="tw-flex tw-items-center tw-px-3 tw-py-2">
																<div className="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between">
																	<div className="tw-truncate">
																		<div className="tw-flex tw-flex-col tw-space-y-4">
																			<div className="tw-flex tw-flex-row tw-items-center tw-space-x-2">
																				{
																					icon
																				}
																				<div
																					// className={`text-14px-med ${selectedRes == i ? "text-white-bis" : "text-brandsecondary"} `}
																					className={`tw-text-14px-med tw-text-brandsecondary tw-max-w-full tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis`}
																				>
																					{section} {title ? `> ${title}` : ""}
																				</div>
																			</div>
																			<div
																				// className={`flex-shrink-0 text-14px-reg ${selectedRes == i ? "text-white-bis" : "text-darkblue/80"}`}
																				className={`tw-flex-shrink-0 tw-text-14px-reg tw-text-black`}
																				dangerouslySetInnerHTML={createMarkup(
																					res.snippet,
																					selectedRes == i,
																				)}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</a>
													</div>
												</dd>
											}
										</>
									);
								})}
							</dl>
						}
					</div>
					<footer className="tw-flex tw-flex-none tw-justify-end">
						<a
							href="https://sqlitecloud.io"
							rel="noreferrer"
							tabIndex="true"
							type="button"
							target="_blank"
							className="tw-text-12px-reg tw-text-black focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-black"
						>
							Powered by <span className="tw-text-brandsecondary">SQLite Cloud</span>
						</a>
					</footer>
				</div>
			</div>
		</FocusTrap>
	);
}
