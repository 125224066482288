//react components
import { useState, useEffect, useContext } from "react";
//next.js
import { useRouter } from 'next/router'
//sqlitecloud lib
import { fetchApiRoute } from "@lib/utils";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud hooks
import { useGetBuildId, useGetSqlitecloudCompanyUser, useGetActualProjectId } from "@custom-hooks/useApi";


const useCheckAuth = (allowAccess = true, majorUpdate = false) => {
	//get build id
	const { buildId } = useGetBuildId();
	const [actualBuildId, setActualBuildId] = useState(undefined);
	useEffect(() => {
		if (!actualBuildId) {
			setActualBuildId(buildId);
		} else {
			if (actualBuildId !== buildId) {
				location.reload();
			}
		}
	}, [buildId]);
	//get from StateContext user simulation
	const { simulateGenericUser } = useContext(StateContext);
	//read user company
	const isSqlitecloudCompanyUser = useGetSqlitecloudCompanyUser();
	//get actual user info
	const projectId = useGetActualProjectId();
	//get next.js router
	const router = useRouter();
	//disable page access for user not from sqlitecloud company
	const disableAccess = !allowAccess && !isSqlitecloudCompanyUser && simulateGenericUser;
	useEffect(() => {
		const checkAuth = () => {
			if (disableAccess) {
				if (projectId) {
					router.push(`/projects/${projectId}/nodes`);
				} else {
					router.push(`/`);
				}
			}
		}
		const handleSignOut = async () => {
			try {
				const opt = {
					method: "GET",
					endpoint: "/api/logout",
					endpointCallLocation: "useCheckAuth.js"
				}
				await fetchApiRoute(opt);
				window.location.href = "/auth/sign-in?major-update=true";
			} catch (error) {
				console.log(error);
			}
		};
		//call the function that check if user can visit this page
		checkAuth();
		if (majorUpdate && !isSqlitecloudCompanyUser) {
			handleSignOut();
		}
	}, [projectId, disableAccess, isSqlitecloudCompanyUser])
}

export { useCheckAuth }