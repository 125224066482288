//react
import React from "react";
//css library
import styled from "@emotion/styled";
//mui components
import { spacing } from "@mui/system";
import Grid from "@mui/material/Grid";
import MuiLinearProgress from "@mui/material/LinearProgress";
const LinearProgress = styled(MuiLinearProgress)(spacing);

const ModalLoader = ({ showLoader }) => {
	//render
	return (
		<>
			{
				showLoader &&
				<Grid item>
					<LinearProgress />
				</Grid>
			}
		</>
	);
}

export default ModalLoader;
