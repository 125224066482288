//react
import React, { Fragment, useEffect, useState, useContext } from "react";
//next.js
//css library
import styled from "@emotion/styled";
//yup
import * as Yup from "yup";
//formik
import { Formik } from "formik";
//mui components
import { spacing } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiLinearProgress from "@mui/material/LinearProgress";
import MuiTextField from '@mui/material/TextField';
const LinearProgress = styled(MuiLinearProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);
//sqlitecloud lib
import { fetchApiRoute } from "@lib/utils";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud hooks
import { useSetError } from "@custom-hooks/useSetError";
import { useSetSnackNotification } from "@custom-hooks/useSetSnackNotification";
//sqlitecloud components
import OpenDialog from "@generic-components/OpenDialog";
import ModalContent from "@generic-components/ModalContent";
import EditCreateModalButtons from "@generic-components/EditCreateModalButtons";
//formik initial value
const initialValues = {
	message: ""
};
//formik validation schema
const validationSchema = Yup.object().shape({
	message: Yup.string().required("Required")
});


function Feedback({
	feedbackRef,
	content = {}
}) {
	//analyze content text
	const title = content.title ? content.title : "Leave your feedback or report an issue";
	const subtitle = content.subtitle ? content.subtitle : "Your feedback is important to improve the SQLite Cloud dashboard.";
	const description = content.description ? content.description : ["Please help us by giving us your thoughts", "so that we may build a better service."];
	//get user info
	const { userInfo: user } = useContext(StateContext);
	//handling open form
	const handleClose = () => {
		setSendStatus(false);
		setSendMailError(null);
		feedbackRef.current.close();
	};
	//handle mail submitting error
	const [sendMailError, setSendMailError] = useState(null);
	useSetError(sendMailError);
	useEffect(() => {
		if (sendMailError) {
			handleClose();
			feedbackRef.current.close();
		}
	}, [sendMailError])
	//get method to update snack notification
	const { createSnackNotification } = useSetSnackNotification();
	//handle positive submission
	const [sendStatus, setSendStatus] = useState(false);
	useEffect(() => {
		if (sendStatus) {
			const SentFeedback = ({ }) => {
				return (
					<Typography variant="14px-reg">Your feedback has been submitted successfully!</Typography>
				)
			}
			const newFeedback = <SentFeedback />;
			createSnackNotification({
				type: "info-2",
				component: newFeedback
			});
			handleClose()
		}
	}, [sendStatus])
	//handling form with Formik
	const handleSubmit = async (
		values,
		{ resetForm, setErrors, setStatus, setSubmitting }
	) => {
		try {
			const firstName = user.first_name;
			const lastName = user.last_name;
			const email = user.email;
			//build message
			const subject = `Feedback from ${firstName} ${lastName} | SQLite Cloud Dashboard`;
			const html = `
              <h2>Feedback received from</h2>
              <ul>
              <li> First Name: ${firstName}</li>
              <li> Last Name: ${lastName}</li> 
              <li> Email: ${email}</li> 
              </ul>
              <h2>User Message</h2>
              <div>
                ${values.message}
              </div>
            `;
			const body = {
				subject: subject,
				html: html,
				type: "contact_us"
			};
			const opt = {
				method: "POST",
				endpoint: "/api/sendMail",
				endpointCallLocation: "Sending  mail",
				body: body
			}
			const sendMailResult = await fetchApiRoute(opt);
			resetForm();
			setStatus({ sent: true });
			setSubmitting(false);
			setSendStatus(true);
		} catch (error) {
			setStatus({ sent: false });
			setErrors({ submit: error });
			setSendMailError(error);
			setSubmitting(false);
			setSendStatus(false);
		}
	};
	//define feedback form to be open inside modal
	const FeedbackForm = ({ }) => {
		return (
			<ModalContent
				actionType="none"
				title={title}
				subtitleSmall={subtitle}
				description={description}
			>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						touched,
						values,
						status,
					}) => {
						const disableSend = (isSubmitting || Object.keys(errors).length !== 0);
						return (
							<>
								{
									isSubmitting && <LinearProgress m={2} />
								}
								{
									!isSubmitting && !status &&
									<form onSubmit={handleSubmit} >
										<TextField
											name="message"
											label="Leave your feedback"
											InputProps={{
												sx: { height: "auto" }
											}}
											fullWidth
											multiline
											value={values.message}
											error={Boolean(touched.message && errors.message)}
											helperText={touched.message && errors.message}
											onBlur={handleBlur}
											onChange={handleChange}
											mt={1}
											rows={4}
										/>
									</form>
								}
								{
									!isSubmitting && !status &&
									<Box mt={"1.125rem"}>
										<EditCreateModalButtons label={"Send"} disabled={disableSend} run={handleSubmit} />
									</Box>
								}
							</>
						)
					}}
				</Formik>
			</ModalContent>
		)
	}
	//render
	return (
		<Fragment>
			<OpenDialog ref={feedbackRef} size={"small-modal"} style={"custom"}>
				<FeedbackForm />
			</OpenDialog>
		</Fragment>
	);
}

export default Feedback;
