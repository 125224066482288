//react
import React, { useContext, useState, useEffect, useRef } from "react";
//mui components
import { useTheme } from '@mui/styles';
import Box from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import NewestNotification from '@mui/icons-material/FirstPage';
import OldestNotification from '@mui/icons-material/LastPage';
import NextNotification from '@mui/icons-material/NavigateNext';
import PrevNotification from '@mui/icons-material/ChevronLeft';
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud Cloud Components
import Card from "@generic-components/Card";
import MegaphoneSVG from "@generic-components/SVG/MegaphoneSVG";
import { Typography } from "@mui/material";
import { maxHeight } from "@mui/system";


const SnackNotification = () => {
	//theme
	const theme = useTheme();
	//read users projects from StateContext
	const { snackNotifications, setSnackNotifications } = useContext(StateContext);
	const [show, setShow] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState(-1);
	const prevSnackNotificationsLength = useRef(0);
	useEffect(() => {
		if (snackNotifications && snackNotifications.length > 0) {
			if (snackNotifications.length > prevSnackNotificationsLength.current) {
				setSelectedNotification(0);
			}
			setShow(true);
		} else {
			setShow(false);
		}
		prevSnackNotificationsLength.current = snackNotifications.length;
	}, [snackNotifications]);
	//handle on close click
	const handleOnClear = () => {
		const newSnackNotifications = snackNotifications.slice(0);
		newSnackNotifications.splice(selectedNotification, 1);
		if (selectedNotification > newSnackNotifications.length - 1) {
			setSelectedNotification(newSnackNotifications.length - 1);
		}
		setSnackNotifications(newSnackNotifications);
	}
	//handle clear all click
	const handleOnClearAll = () => {
		const newSnackNotifications = [];
		setSnackNotifications(newSnackNotifications);
		setShow(false);
		setSelectedNotification(-1);
	}
	//handle newest notification click
	const handleSelectNewestNotification = () => {
		setSelectedNotification(0);
	}
	//handle next notification click
	const handleSelectNextNotification = () => {
		const newIndex = selectedNotification - 1 >= 0 ? selectedNotification - 1 : 0;
		setSelectedNotification(newIndex);
	}
	//handle oldest notification click
	const handleSelectOldestNotification = () => {
		setSelectedNotification(snackNotifications.length - 1);
	}
	//handle prev notification click
	const handleSelectPrevNotification = () => {
		const newIndex = selectedNotification + 1 < snackNotifications.length ? selectedNotification + 1 : snackNotifications.length - 1;
		setSelectedNotification(newIndex);
	}
	//extract component and type from last notification
	const { type, component } = snackNotifications[selectedNotification] ? snackNotifications[selectedNotification] : { type: "info-2", component: null };
	//define style based on type
	const notificationSx = { maxHeight: "calc(100vh - 7.54rem)", overflowY: "auto", position: "fixed", width: "18rem", bottom: 60, right: 24, padding: "2rem 1rem  0 1rem", zIndex: 1290 }
	//render
	return (
		<>
			{
				show &&
				<Card customSx={notificationSx} type={type} >
					{/* ACTIONS */}
					<Box sx={{ position: "absolute", right: "0", top: "0" }}>
						<Grid container>
							<Tooltip title={"Clear all"}>
								<span>
									<IconButton color="secondary" onClick={handleOnClearAll}>
										<ClearAllIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title={"Clear"}>
								<span>
									<IconButton color="secondary" onClick={handleOnClear}>
										<CloseIcon />
									</IconButton>
								</span>
							</Tooltip>
						</Grid>
					</Box>
					<Grid container spacing={2}>
						<Grid item >
							<MegaphoneSVG />
						</Grid>
						<Grid item width={"100%"}>
							{React.Children.map(component, child => React.cloneElement(child, { callback: handleOnClear }))}
						</Grid>
						<Grid item margin={"0 auto"}>
							{/* PAGINATION */}
							<Grid container direction={"row"} alignItems={"center"} justifyContent={"center"}>
								<Tooltip title={"Newest"}>
									<span>
										<IconButton size="small" color="secondary" onClick={handleSelectNewestNotification} disabled={selectedNotification === 0}>
											<NewestNotification />
										</IconButton>
									</span>
								</Tooltip>
								<Tooltip title={"Next"}>
									<span>
										<IconButton size="small" color="secondary" onClick={handleSelectNextNotification} disabled={selectedNotification === 0}>
											<PrevNotification />
										</IconButton>
									</span>
								</Tooltip>
								<Typography fontSize={"0.7rem"} fontFamily={"Roboto Mono"}>{selectedNotification + 1} / {snackNotifications.length}</Typography>
								<Tooltip title={"Prev"}>
									<span>
										<IconButton size="small" color="secondary" onClick={handleSelectPrevNotification} disabled={selectedNotification === snackNotifications.length - 1}>
											<NextNotification />
										</IconButton>
									</span>
								</Tooltip>
								<Tooltip title={"Oldest"}>
									<span>
										<IconButton size="small" color="secondary" onClick={handleSelectOldestNotification} disabled={selectedNotification === snackNotifications.length - 1}>
											<OldestNotification />
										</IconButton>
									</span>
								</Tooltip>
							</Grid>
						</Grid>
					</Grid>
				</Card >
			}
		</>
	);
}

export default SnackNotification;
