//react
import React, { useContext, useState, useLayoutEffect, useEffect } from "react";
import styled from "@emotion/styled";
//next.js
import Link from "next/link";
import { useRouter } from "next/router";
//mui componponents
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse"
import IconButton from '@mui/material/IconButton';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
//sqlitecloud utils
import { removeQueryString } from "@lib/utils";
//sqlitecloud context
import { StateContext } from "@context/StateContext"
//sqlitecloud hooks
import { useGetActualNodesNumber } from "@custom-hooks/useApi"
import { useSetSnackNotification } from "@custom-hooks/useSetSnackNotification";

const Item = styled(ListItemButton)`
	height: ${(props) => props.isMobile ? '50' : '34'}px;
	margin: ${(props) => props.isMobile ? '0' : '0.5'}rem 0px;
	padding-top: ${(props) =>
		props.theme.spacing(/* props.depth && props.depth > 0 ? 2 : */ 3)};
  	padding-bottom: ${(props) =>
		props.theme.spacing(/* props.depth && props.depth > 0 ? 2 : */ 3)};
  	padding-left: ${(props) =>
		props.theme.spacing(/* props.depth && props.depth > 0 ? 8 : */ 4)};
  	padding-right: ${(props) =>
		props.theme.spacing(/* props.depth && props.depth > 0 ? 4 : */ 7)};
	border-radius: 8px;
  	svg {
    	font-size: 20px;
    	width: 20px;
    	height: 20px;
		stroke: ${(props) => props.experimental === "true" ? props.theme.palette.error.main : ""};
  	}
	cursor: ${(props) => props.href ? "pointer" : "default"};
	span {
		color: ${(props) => props.experimental === "true" ? props.theme.palette.error.main : props.theme.palette.secondary.accent4};
	};
  	&:hover {
    	background: ${(props) => props.href ? props.theme.palette.neutral.cream : "transparent"};
  	}
	&:focus-visible {
    	border: 1px ${(props) => props.theme.palette.secondary.accent2} solid;
  	}
  	&.${(props) => props.activeclassname} {
		border: 0;
    	background-color: ${(props) => props.theme.palette.neutral.cream};
    	span {
      		color: ${(props) => props.experimental === "true" ? props.theme.palette.error.main : props.theme.palette.secondary.accent2};
		};
		svg {
			stroke: ${(props) => props.experimental === "true" ? props.theme.palette.error.main : props.theme.palette.secondary.accent2};
		}
  	}
	button.svg {
		height: 18px;
		width: 18px;
	}
	button {
		margin-left: ${(props) => props.isMobile ? "12rem" : undefined};
		width: 30px;
		height: 30px;
	}
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => (props.depth && props.depth) > 0 ? props.theme.palette.secondary.accent3 : props.theme.palette.secondary.accent4};
	font-size: ${(props) => (props.depth && props.depth) > 0 ? '15' : '14'}px;
	font-style: normal;
 	font-weight: ${(props) => (props.depth && props.depth) > 0 ? 400 : 450};
 	padding: 0 ${(props) => props.theme.spacing(2)};
 	margin: 0 0 0 ${(props) => props.theme.spacing(2 * props.depth)}};
	&.${(props) => props.activeclassname} {
		span {
			color: ${(props) => props.theme.palette.secondary.accent2};
		};
		svg {
			stroke: ${(props) => props.theme.palette.secondary.accent2};
		}
  	}
`;

const SidebarNavListItem = (props) => {
	//extract props
	const {
		title,
		href,
		depth = 0,
		slug,
		projectIndex, //Added SQLite
		children,
		icon: Icon,
		badge,
		open: openProp = false,
		experimental,
		isProjectsHome,
		isMobile,
		hideSideBarOnDesk
	} = props;
	//state context
	const { userProjects, setActualProjectIndex } = useContext(StateContext);
	//read query parameters from url
	const { asPath } = useRouter();
	const [basePath, setBasePath] = useState("");
	//read actual project node
	const actualNodesNumber = useGetActualNodesNumber();
	//verify that nodes number is greater than zero
	// const haveNodes = (userProjects && userProjects[projectIndex] && userProjects[projectIndex].nodes_count > 0) ? true : false;
	const haveNodes = actualNodesNumber && actualNodesNumber > 0 ? true : false;
	//get method to update snack notification
	const { createSnackNotification } = useSetSnackNotification();
	//on first layout check if the visited page correspond to the one registerd in href key of the list item
	//if yes, save the projectIndex in the StateContext
	useLayoutEffect(() => {
		//search if in the path there is the base slug.
		//this is important because some sidebar items, e.g. nodes, has sub pages, connections, and we want
		//that the string in the sidebar remains in evidence when someone visit the subpage
		var testBasePath;
		if (asPath.includes(slug)) {
			asPath = removeQueryString(asPath);
			if (!children) {
				asPath = asPath.substring(0, asPath.indexOf(slug) + slug.length)
			}
			testBasePath = asPath;
			if (testBasePath === href) {
				setActualProjectIndex(projectIndex);
				setBasePath(testBasePath);
			} else {
				setBasePath("");
			}
		} else {
			setBasePath("");
		}
	}, [asPath]);
	//handle item with child open state
	// const [open, setOpen] = React.useState(false);
	const [open, setOpen] = React.useState(openProp);
	useEffect(() => {
		if (!open) {
			setOpen(openProp)
		}
	}, [openProp])
	const handleToggle = () => {
		setOpen((state) => !state);
	};
	//check if an item must be disabled
	const disableItem = (!haveNodes && title !== "Nodes") ? true : false;
	//handle item click
	const handleLinkClick = () => {
		if (disableItem) {
			const AlertNoNodes = ({ }) => {
				return (
					<Typography variant="14px-reg">This feature is disabled because the project does not contain any node.</Typography>
				)
			}
			const newAlertNoNodes = <AlertNoNodes />;
			createSnackNotification({
				type: "info-2",
				component: newAlertNoNodes
			});
		}
	}
	if (!isProjectsHome) {
		if (children) {
			return (
				<React.Fragment>
					<Link href={disableItem || href === "" ? "#" : href} passHref>
						<a className="unstyled-link" onClick={handleLinkClick}>
							<Item
								href={href}
								className={(basePath == href && href !== "") ? "active" : ""}
								activeclassname="active"
								depth={depth}
								disabled={disableItem}
								// onClick={handleToggle}
								experimental={experimental ? experimental.toString() : "false"}
								isMobile={isMobile}
							>
								{Icon && <Icon />}
								{
									!hideSideBarOnDesk &&
									<Title depth={depth}>
										{title}
									</Title>
								}
								<IconButton onClick={(event) => { event.stopPropagation(); event.preventDefault(); handleToggle() }} >
									{open ? <ExpandLess /> : <ExpandMore />}
								</IconButton>
							</Item>
						</a>
					</Link>
					<Collapse unmountOnExit in={open}>{children}</Collapse>
				</React.Fragment >
			);
		}

		return (
			<>
				<Link href={disableItem ? "#" : href} passHref>
					<a className="unstyled-link" onClick={handleLinkClick}>
						<Item
							href={href}
							depth={depth}
							className={basePath == href ? "active" : ""}
							activeclassname="active"
							disabled={disableItem}
							experimental={experimental ? experimental.toString() : "false"}
							isMobile={isMobile}
						>
							{Icon && <Icon />}
							{
								!hideSideBarOnDesk &&
								<Title depth={depth}>
									{title}
								</Title>
							}
						</Item>
					</a>
				</Link>
			</>
		);
	} else {
		return (
			<></>
		)
	}
};

export default SidebarNavListItem;
