//react
import React from "react";
//mui components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const DoubleActionModalButtons = ({
	firstButtonLabel, secondButtonLabel,
	firstButtonAction, secondButtonAction,
	firstButtonDisable, secondButtonDisable
}) => {
	//render
	return (
		<Grid item>
			<Grid container width={"100%"} justifyContent={"center"} columnSpacing={2}>
				<Grid item>
					<Button disabled={firstButtonDisable} variant="modal-secondary" onClick={firstButtonAction} >
						{firstButtonLabel}
					</Button>
				</Grid>
				<Grid item>
					<Button disabled={secondButtonDisable} variant="modal-primary" onClick={secondButtonAction} >
						{secondButtonLabel}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}
export default DoubleActionModalButtons;