import {
	nodes,
	database,
	console,
	edgeFunctions,
	webhooks,
	backups,
	logs,
	settings,
	weblite,
	queryAnalyzer
} from "./icons";

//this function creates the object with the right structure to popolute the sidebar 
const buildSidebarObj = (projects, isSqlitecloudCompanyUser) => {
	//build page structure
	var pagesSection = [];
	projects.forEach((project, projectIndex) => {
		pagesSection.push(
			{
				href: "#",
				icon: nodes,
				title: project.name,
				description: project.description, //sqlitecloud modification
				projectId: project.id, //sqlitecloud modification
				projectIndex: projectIndex, //sqlitecloud modification
				children: [
					{
						href: `/projects/${project.id}/nodes`,
						slug: "nodes",
						title: "Nodes",
						projectIndex: projectIndex, //sqlitecloud modification
						icon: nodes
					},
					{
						href: `/projects/${project.id}/databases`,
						slug: "databases",
						title: "Databases",
						projectIndex: projectIndex, //sqlitecloud modification
						icon: database,
						children: [
							{
								href: `/projects/${project.id}/databases/tables`,
								slug: "tables",
								title: "Tables",
								projectIndex: projectIndex, //sqlitecloud modification
							}
						]
					},
					{
						href: `/projects/${project.id}/console`,
						slug: "console",
						title: "Console",
						projectId: project.id, //sqlitecloud modification            
						projectIndex: projectIndex, //sqlitecloud modification
						icon: console
					},
					{
						href: `/projects/${project.id}/edge-functions`,
						slug: "edge-functions",
						title: "Edge Functions",
						projectIndex: projectIndex, //sqlitecloud modification
						icon: edgeFunctions
					},
					{
						href: `/projects/${project.id}/webhooks`,
						slug: "webhooks",
						title: "Webhooks",
						projectIndex: projectIndex, //sqlitecloud modification
						icon: webhooks
					},
					{
						href: `/projects/${project.id}/backups`,
						slug: "backups",
						title: "Backups",
						projectIndex: projectIndex, //sqlitecloud modification
						icon: backups
					},
					{
						href: `/projects/${project.id}/logs`,
						slug: "logs",
						title: "Logs",
						projectIndex: projectIndex, //sqlitecloud modification
						icon: logs
					},
					{
						href: `/projects/${project.id}/weblite`,
						slug: "weblite",
						title: "Weblite",
						projectIndex: projectIndex, //sqlitecloud modification
						icon: weblite,
					},
					{
						href: `/projects/${project.id}/query-analyzer`,
						slug: "query-analyzer",
						title: "Query analyzer",
						projectIndex: projectIndex, //sqlitecloud modification
						icon: queryAnalyzer,
					},
					{
						href: ``,
						slug: "settings",
						title: "Settings",
						projectId: project.id, //sqlitecloud modification
						projectIndex: projectIndex, //sqlitecloud modification
						icon: settings,
						children: [
							{
								href: `/projects/${project.id}/settings/users`,
								slug: "users",
								title: "Users",
								projectIndex: projectIndex, //sqlitecloud modification
							},
							{
								href: `/projects/${project.id}/settings/roles`,
								slug: "roles",
								title: "Roles",
								projectIndex: projectIndex, //sqlitecloud modification
							},
							{
								href: `/projects/${project.id}/settings/api-keys`,
								slug: "api-keys",
								title: "API Keys",
								projectIndex: projectIndex, //sqlitecloud modification
							},
							{
								href: `/projects/${project.id}/settings/environment-variables`,
								slug: "environment-variables",
								title: "Env Variables",
								projectIndex: projectIndex, //sqlitecloud modification
							},
							{
								href: `/projects/${project.id}/settings/advanced-settings`,
								slug: "advanced-settings",
								title: "Advanced",
								projectIndex: projectIndex, //sqlitecloud modification
							},
							isSqlitecloudCompanyUser ?
								{
									href: `/projects/${project.id}/settings/ip-restriction`,
									slug: "ip-restriction",
									title: "IP Restriction",
									projectIndex: projectIndex, //sqlitecloud modification,
									experimental: true
								} : null,
							isSqlitecloudCompanyUser ?
								{
									href: `/projects/${project.id}/settings/plugins`,
									slug: "plugins",
									title: "Plugins",
									projectIndex: projectIndex, //sqlitecloud modification
									experimental: true
								} : null,
							isSqlitecloudCompanyUser ?
								{
									href: `/projects/${project.id}/settings/commands`,
									slug: "commands",
									title: "Commands",
									projectIndex: projectIndex, //sqlitecloud modification
									experimental: true
								} : null,
						],
					}
				]
			},
		)
	})


	const sideBareItems = [
		{
			title: "Projects",
			pages: pagesSection,
		}
	];

	return sideBareItems;
}


export { buildSidebarObj }