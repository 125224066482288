//react components
import React, { useState, useEffect, useContext } from "react";
//next.js
import { useRouter } from 'next/router';
//css library
import styled from "@emotion/styled";
//mui components
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MuiLinearProgress from "@mui/material/LinearProgress";
const LinearProgress = styled(MuiLinearProgress)(spacing);
import GlobalStyle from "@components/GlobalStyle";
import NavBar from "@components/navbar/NavBar";
import Sidebar from "@components/sidebar/Sidebar";
//sqlitecloud library
import { buildSidebarObj } from "@lib/dashboardUtils"
import { generateRandomId, delay, removeMapLabels } from "@lib/utils";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud hooks
import { useGetUser, useGetSqlitecloudCompanyUser, useGetProjects, useGetRenewToken, useGetActualProjectId, useUploadDatabase } from "@custom-hooks/useApi";
//sqlitecloud components
import SnackNotification from "@generic-components/SnackNotification";


const sideBarWidthDesk = {
	open: "15.375rem",
	closed: "5.3rem"
}

const Paper = styled(MuiPaper)(spacing);

const RootValidating = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const WrapperValidating = styled(Paper)`
  width:100%; 
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;



const Dashboard = ({ children }) => {
	//get theme
	const theme = useTheme();
	const isMobile = !(useMediaQuery(theme.breakpoints.up("md")));
	useEffect(() => {
		if (!isMobile) {
			setMobileOpen(false);
		}
		if (isMobile) {
			setHideSideBarOnDesk(false);
		}
	}, [isMobile])
	//read user data
	useGetUser();
	//read user projects
	useGetProjects();
	//get router
	const router = useRouter();
	//read parameters from url
	const { pathname, asPath } = useRouter();
	useEffect(() => {
		setMobileOpen(false);
	}, [router])
	const slug = pathname.split('/').pop();
	//call the api to check if the token has to be refresh
	const getRenewToken = useGetRenewToken();
	useEffect(() => {
		removeMapLabels();
	}, [getRenewToken])
	//read stateContext
	const { setTabId, userInfo, dashboardItems, setDashboardItems, userProjects } = useContext(StateContext);
	useEffect(() => {
		const tabId = "sqlitecloud-tab-" + generateRandomId();
		setTabId(tabId);
	}, [])
	//hide the UI until the credetial are validated
	const [showUI, setShowUI] = useState(false);
	useEffect(() => {
		const showUICheck = async () => {
			if (userInfo) {
				await delay(200);
				setShowUI(true);
			}
		}
		showUICheck();
	}, [userInfo]);
	//save in localstorage a unique ID corresponding to 
	//read user company
	const isSqlitecloudCompanyUser = useGetSqlitecloudCompanyUser();
	//once received user projects and user info, builds obj dedicate to the sidebar
	useEffect(() => {
		if (userProjects) {
			setDashboardItems(buildSidebarObj(userProjects, isSqlitecloudCompanyUser));
		}
	}, [userProjects, isSqlitecloudCompanyUser]);
	//get if the user has zero project
	const isFirstProject = userProjects && userProjects.length === 0;
	//get actual project id
	const projectId = useGetActualProjectId();
	//one received user projects and built obj dedicated to sidebar, based on acutal project id and the user projects decides if redirect, or if we are in homepage or subpages
	const [isProjectsHome, setIsProjectsHome] = useState(true);
	const showChildren = isProjectsHome ? true : true;
	useEffect(() => {
		if (dashboardItems) {
			if (slug === "") {
				setIsProjectsHome(true);
			} else {
				setIsProjectsHome(false);
			}
		}
	}, [dashboardItems, slug]);
	useEffect(() => {
		if (userProjects && dashboardItems) {
			//if projectId is false means that it is not a project of the current user. so redirect to 404 page
			if (projectId === false) {
				router.replace(`/404`, asPath);
			} else if (projectId) {
				userProjects.forEach((project) => {
					if (project.id == projectId && project.nodes_count === 0) {
						if (slug !== "/nodes" && slug !== "") {
							router.push(`/projects/${projectId}/nodes`);
						}
					}
				})
			}
		}
	}, [userProjects, dashboardItems, projectId, slug]);
	//helper function to filter download and upload queue based on projectId
	function filterMapBasedOnProjectId(map, id) {
		let filteredItems = new Map();
		map.forEach((value, key) => {
			if (value.projectId === id) {
				filteredItems.set(key, value);
			}
		});
		return filteredItems;
	}
	//upload databases
	const { uploadQueue, uploadDb } = useUploadDatabase();
	const uploadOpts = {
		uploadQueue: projectId ? filterMapBasedOnProjectId(uploadQueue, projectId) : uploadQueue,
		uploadDb
	}
	//handle main sidebar open state from mobile
	const [mobileOpen, setMobileOpen] = useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	//handle opet state of single page dedicated sidebar
	//https://react.dev/reference/react-dom/createPortal
	const [customPageSidebar, setCustomPageSidebar] = useState(null);
	const handleCustomPageSidebar = (component) => {
		setCustomPageSidebar(component);
	}
	//handle hide sidebar on desktop
	const [hideSideBarOnDesk, setHideSideBarOnDesk] = useState(false);
	const handleHideSideBarOnDesk = (state) => {
		setHideSideBarOnDesk(state);
	}

	//render
	return (
		<>
			<CssBaseline />
			<GlobalStyle />
			{
				!showUI &&
				<RootValidating>
					<Box
						component="img"
						sx={{
							height: 64,
							width: "auto",
							marginBottom: "32px",
						}}
						alt="SQlite Cloud"
						src="/static/img/logo/logo@4x.png"
					/>
					<WrapperValidating>
						{
							!userInfo &&
							<>
								<Typography component="h1" variant="h4" align="center" gutterBottom>
									Validating your credentials
								</Typography>
								<LinearProgress my={8} />
							</>
						}
						{
							userInfo &&
							<Typography component="h1" variant="h4" align="center" gutterBottom>
								Welcome back, {userInfo.first_name}!
							</Typography>
						}
					</WrapperValidating>
				</RootValidating>
			}
			{
				showUI &&
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					boxSizing: "border-box",
					minHeight: "100%"
				}}>
					<Box sx={{
						flex: "none"
					}} />
					<Box sx={{
						height: "100vh",
						flex: "1 1 0px",
						boxSizing: "border-box",
						minHeight: "0",
						height: "100vh"
					}}>
						<Box sx={{
							display: "flex",
							height: "100%"
						}}>
							<Sidebar
								items={dashboardItems}
								isMobile={isMobile}
								open={mobileOpen}
								hideSideBarOnDesk={hideSideBarOnDesk}
								handleHideSideBarOnDesk={handleHideSideBarOnDesk}
								uploadOpts={uploadOpts}
								showNavTree={!isProjectsHome || isMobile}
								customPageSidebar={customPageSidebar}
								sideBarWidthDesk={sideBarWidthDesk}
								isProjectsHome={isProjectsHome}
								onDrawerToggle={handleDrawerToggle}
							/>
							<Box sx={{
								maxWidth: "100%",
								display: "flex",
								flex: "1 1 0%",
								flexDirection: "column",
								paddingTop: isMobile ? "0" : "0.625rem",
								maxWidth: (isMobile || isProjectsHome || isFirstProject) ? "100%" : hideSideBarOnDesk ? `calc(100% - ${sideBarWidthDesk.closed})` : `calc(100% - ${sideBarWidthDesk.open})`
							}}>
								<NavBar
									isProjectsHome={isProjectsHome}
									isMobile={isMobile}
									dashboardItems={dashboardItems}
									isFirstProject={isFirstProject}
									onDrawerToggle={handleDrawerToggle}
									mobileOpen={mobileOpen}
								/>
								<Box sx={{
									flex: "1 1 0%",
									flexGrow: "1",
									boxSizing: "border-box",
									overflowY: "auto",
									// background: "pink",
									padding: isProjectsHome ? isMobile ? "0 1rem" : "1.25rem 1.5rem 0 2.625rem" : isMobile ? "0 1rem 1rem" : "1.25rem 1.5rem 1.375rem 2.625rem",
									overflowX: isFirstProject ? "hidden" : "auto",
									display: "flex",
									flexDirection: "column",
								}}>
									{
										(showChildren) ?
											// children 
											React.Children.map(children, child => React.cloneElement(child, { uploadOpts: uploadOpts, handleCustomPageSidebar }))
											:
											<></>
									}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box >
			}
			<SnackNotification />
			{/* <LoaderNotification /> */}
		</>
	);
};

export default Dashboard;