//react
import React, { useState } from "react";
//mui components
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//mui components
import CopySvg from "@generic-components/SVG/CopySvg";
//sqlitecloud lib
import { hideMiddlePartOfString } from "@lib/utils";
//sqlitecloud hooks
import { useCopyToClipboard } from "@custom-hooks/useCopyToClipboard";

const CopyToClipboardButton = ({ label, value, visibility, alignLeft = false, boxSx = {}, buttonSx = {}, iconColor = "#2175E2", hideValue = undefined }) => {
	//get method to copy to clipboard
	const { copyToClipboard, copiedToClipboard } = useCopyToClipboard()
	//ustom sx
	// boxSx.width = "50%";
	boxSx.ml = alignLeft ? -2 : 0;
	//handle show value
	const [showValue, setShowValue] = useState(false);
	const handleClickShowValue = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setShowValue((show) => !show)
	};
	//hide part of the value to be dispalied
	if (hideValue) {
		if (showValue) {
			label = label
		} else {
			label = hideMiddlePartOfString(label, hideValue.start, hideValue.end)
		}
	}
	//return
	return (
		<Box sx={boxSx}>
			{/* <Button variant="copy-to-clipboard" size="small" endIcon={copiedToClipboard ? "" : <CopySvg visibility={visibility} iconColor={iconColor} />} sx={buttonSx} onClick={() => { copyToClipboard(value) }}> */}
			<Button variant="copy-to-clipboard" size="small" sx={buttonSx} onClick={(event) => {
				event.preventDefault();
				event.stopPropagation();
				copyToClipboard(value);
			}}>
				{
					copiedToClipboard &&
					<span style={{ color: iconColor }}>Copied!</span>
				}
				{
					!copiedToClipboard &&
					<span style={{ marginRight: "0.5rem", wordBreak: "break-all" }}>{label}</span>
				}
				{
					copiedToClipboard ? <span></span> : <CopySvg visibility={visibility} iconColor={iconColor} />
				}
				{
					hideValue &&
					<IconButton
						onClick={handleClickShowValue}
						edge="end"
						sx={visibility}
						disableRipple={true}
					// sx={{ mr: 2, p: 0 }}
					>
						{showValue ? <VisibilityOff sx={{ fontSize: "1rem", color: iconColor }} /> : <Visibility sx={{ fontSize: "1rem", color: iconColor }} />}
					</IconButton>
				}
			</Button>
		</Box>
	);
}

export default CopyToClipboardButton;
