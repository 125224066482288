//react components
import React, { useContext, useState, useEffect, Fragment } from "react";
//next.js
import Link from "next/link";
import { useRouter } from "next/router";
//mui components
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//SQlite utils
import { capitalizeFirstLetter } from "@lib/utils";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud lib
import { useGetNodes, useGetDatabases, useGetActualProjectId } from "@custom-hooks/useApi";


function Breadcrumbs({ dashboardItems }) {
	/* COMMENTED SINCE WE DON'T ADD ANYMORE PAGE NAME TO BREADCRUMS	
	const projectStructure = dashboardItems[0].pages[0]?.children;
	//create obg containing for each possible page slug and corresponding title
	let pagesName = {};
	function extractPagesName(children, pagesName) {
		if(children){
			children.forEach((child) => {
				if (child) {
					pagesName[child.slug] = { title: child.title, experimental: child.experimental };
					if (child.children) {
						extractPagesName(child.children, pagesName)
					}
				}
			})
		}
	}
	extractPagesName(projectStructure, pagesName);
	//useRouter
	const { query, route } = useRouter();
	//get nodes and databases
	const { nodes } = useGetNodes();
	const { databases } = useGetDatabases();
	*/

	//layout
	const theme = useTheme();
	//use context
	const { userProjects, userInfo: user } = useContext(StateContext);
	//get actual project id
	const projectId = useGetActualProjectId();
	//name of selected project
	const [selectedProjectName, setSelectedProjectName] = useState(null);
	useEffect(() => {
		if (projectId && userProjects) {
			userProjects.forEach((project) => {
				if (project.id == projectId) {
					setSelectedProjectName(project.name);
				}
			})
		}
	}, [projectId, userProjects]);

	/* COMMENTED SINCE WE DON'T ADD ANYMORE PAGE NAME TO BREADCRUMS	
	//build route
	const [path, setPath] = useState([]);
	useEffect(() => {
		let path = [];
		if (route) {
			const routeList = route.split("/");
			routeList.forEach((el, i) => {
				if (i > 2) {
					if (el.includes("[")) {
						const queryParameter = el.slice(1, -1);
						const queryValue = query[queryParameter];
						switch (queryParameter) {
							case "nodeId":
								if (nodes) {
									nodes.forEach((node) => {
										if (node.id == queryValue) {
											path.push(node.name);
										}
									})
								} else {
									path.push("...");
								}
								break;
							case "databaseId":
								if (databases) {
									databases.forEach((node) => {
										if (databases.id == queryValue) {
											path.push(databases.name);
										}
									})
								} else {
									path.push("...");
								}
								break;
							case "databaseName":
								path.push(queryValue);
								break;
							default:
						}
					}
					if (!el.includes("[")) {
						if (pagesName[el]) {
							path.push(pagesName[el]);
						} else {
							path.push(capitalizeFirstLetter(el));
						}
					}
				}
			})
		}
		setPath(path);
	}, [route, databases, nodes]);
	*/

	//render
	return (
		<Grid
			container
			alignItems={"center"}
		>
			<Grid item>
				<Link href="/" passHref>
					<a className="unstyled-link">
						<Typography
							fontSize={{ xs: "0.9375rem", md: "1rem" }}
							lineHeight={{ xs: "160%", md: "125%" }}
							fontWeight="400" color={theme.palette.secondary.accent3}
							sx={{ 
								"cursor": "pointer",
								"whiteSpace": "nowrap",
								"overflow": "hidden",
								"textOverflow": "ellipsis",
								"maxWidth": "15rem",
							 }}
						>
							{user ? user.first_name : "..."} {user ? user.last_name : "..."}
						</Typography>
					</a>
				</Link>
			</Grid>
			{
				projectId &&
				<>
					<Grid item>
						<Grid container justifyContent={"center"}>
							<ChevronRightIcon sx={{ color: theme.palette.neutral.lightGrey }} />
						</Grid>
					</Grid>
					<Grid item>
						<Typography
							fontSize={{ xs: "0.9375rem", md: "1rem" }}
							lineHeight={{ xs: "160%", md: "125%" }}
							fontWeight="500"
							color={theme.palette.secondary.accent2}
							sx={{
								"whiteSpace": "nowrap",
								"overflow": "hidden",
								"textOverflow": "ellipsis",
								"maxWidth": "15rem",
							}}
						>
							{selectedProjectName ? selectedProjectName : "..."}
						</Typography>
					</Grid>
					{/* {
						path.map((pathEl, i) =>
							<Fragment key={i}>
								<Grid item>
									<Grid item>
										<Grid container justifyContent={"center"}>
											<ChevronRightIcon sx={{ color: theme.palette.neutral.lightGrey }} />
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Typography fontSize="1rem" lineHeight={"125%"} fontWeight="500" color={pathEl.experimental ? theme.palette.error.main : theme.palette.secondary.accent2}>
										{pathEl.title}
									</Typography>
								</Grid>
							</Fragment>
						)
					} */}
				</>
			}
		</Grid>
	);
}


export default Breadcrumbs;
