//react components
import { useContext, useEffect } from "react";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud lib
import { hasObjectWithId, removeObjectById } from "@lib/utils";

const useSetLoader = () => {
	//get snackNotifications from context
	const { loadingNotifications, setLoadingNotifications } = useContext(StateContext);
	const createLoader = (loader) => {
		const renderCondition = loader;
		if (renderCondition) {
			if (loader.state) {
				if (!hasObjectWithId(loadingNotifications, loader.id)) {
					const newLoadingNotifications = loadingNotifications.slice(0);
					newLoadingNotifications.push(loader);
					setLoadingNotifications(newLoadingNotifications);
				}
			} else {
				const newLoadingNotifications = removeObjectById(loadingNotifications, loader.id);
				setLoadingNotifications(newLoadingNotifications);
			}
		}
	}
	return { createLoader };
}

export { useSetLoader }

