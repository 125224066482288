//react
import React, { useContext, Fragment } from "react";
//next.js
import { useRouter } from "next/router";
//mui components
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/styles';
//sqlitecloud hooks
import { useGetActualSlug } from "@custom-hooks/useApi";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud components
import Card from "@generic-components/Card";

function PageTitle({ title, subtitle = undefined, badge = undefined, banner = undefined, mt = 0, mb = 3, fontSize = "1rem", justifyContent = "space-between", alignItems = "center", children }) {
	//get theme
	const theme = useTheme();
	//get actual slug
	const slug = useGetActualSlug();
	//get context
	const { dashboardItems } = useContext(StateContext);
	let projectStructure = [];
	if (slug !== "") {
		projectStructure = dashboardItems && dashboardItems[0] && dashboardItems[0].pages[0] && dashboardItems[0].pages[0].children ? dashboardItems[0].pages[0].children : [];
	}
	let experimentalPage = false;
	projectStructure.forEach(element => {
		if (element) {
			if (element.children) {
				element.children.forEach((child) => {
					if (child && child.slug === slug && child.experimental) {
						experimentalPage = true;
					}
				})
			} else {
				if (element.slug === slug && element.experimental) {
					experimentalPage = true;
				}
			}
		}
	});
	//render
	return (
		<Grid mt={mt} mb={mb} pr={1} item>
			<Grid container flexDirection={"column"} rowGap={5}>
				<Grid item>
					<Grid container flexDirection={{ xs: "row", sm: "row" }} minHheight={"2.375rem"} justifyContent={justifyContent} alignItems={alignItems} columnGap={6} rowGap={2}>
						<Grid item>
							<Badge
								sx={{
									"& .MuiBadge-badge": {
										color: "#FEFEFE",
										fontSize: "0.8rem",
										fontFamily: "Roboto Mono",
										padding: 2,
										marginRight: "-2rem"
									}
								}}
								badgeContent={badge}
								color="primary"
							>
								<Typography as="div" color={experimentalPage ? theme.palette.error.main : theme.palette.secondary.accent4} sx={{ fontSize: fontSize, fontWeight: 500, lineHeight: "125%" }}>
									{title}
								</Typography>
							</Badge>
						</Grid>
						<Grid item flexGrow={1}>
							<Grid container rowGap={2} columnSpacing={3} justifyContent={{ xs: "flex-end", sm: "flex-end" }}>
								{children}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{
					subtitle &&
					<Grid item maxWidth={"60rem"} pr={4}>
						<Grid item>
							<Typography as="div" variant="14px-light" align="left" gutterBottom={true}>
								{
									Array.isArray(subtitle) &&
									<>
										{
											subtitle.map(
												(line, i) => <span key={i}>{line}<br /></span>)
										}
									</>
								}
								{
									!Array.isArray(subtitle) &&
									<>
										{subtitle}
									</>
								}
							</Typography>
						</Grid>
					</Grid>
				}
				{
					banner &&
					<Grid item width={"fit-content"} pr={4}>
						<Card type="warning">
							<Grid item>
								{banner}
							</Grid>
						</Card>
					</Grid>
				}
			</Grid>
		</Grid >
	);
}

export default PageTitle;
