import { Global, css } from "@emotion/react";

const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #__next {
        height: 100%;
      }

      body {
        margin: 0;
			}
    `}
  />
);

export default GlobalStyle;
