//react
import React, { Fragment, useEffect, useState, useContext } from "react";
//swr
import { useSWRConfig } from 'swr'
//css library
import styled from "@emotion/styled";
//yup
import * as Yup from "yup";
//formik
import { Formik } from "formik";
//mui components
import { spacing } from "@mui/system";
import Grid from "@mui/material/Grid";
import MuiButton from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MuiLinearProgress from "@mui/material/LinearProgress";
import MuiTextField from '@mui/material/TextField';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Switch from "@mui/material/Switch";
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
//sqlitecloud components
import CustomSwitch from "@components/GenericElements/CustomSwitch";

//sqlitecloud constant
const EXPERIMENTAL_COMPANIES_ID = process.env.NEXT_PUBLIC_EXPERIMENTAL_COMPANIES_ID;
const experimentalCompaniesId = EXPERIMENTAL_COMPANIES_ID.split(',');
//sqlitecloud lib
import { fetchApiRoute } from "@lib/utils";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud hooks
import { useSetError } from "@custom-hooks/useSetError";
import { useSetSnackNotification } from "@custom-hooks/useSetSnackNotification";
//sqlitecloud componenets
import ModalContent from "@generic-components/ModalContent";
import InputWithCustomLabel from "@generic-components/Form/InputWithCustomLabel";

function Profile({ callback }) {
	//get simulation generic user from context
	const { simulateGenericUser, setSimulateGenericUser, userInfo } = useContext(StateContext);
	//check if it is a user that belog to a company id enabled for experimental features
	const companyId = userInfo.company_id.toString();
	const testCompany = experimentalCompaniesId.includes(companyId);
	//handle change in enabling state
	const handleSimulateGenericUserState = () => {
		setSimulateGenericUser(!simulateGenericUser);
		//save in local storage setSimulateGenericUser state
		var jsonData = JSON.stringify(!simulateGenericUser);
		localStorage.setItem(`${userInfo.company}-${userInfo.id}`, jsonData);
	};
	//swr
	const { mutate } = useSWRConfig();
	//handling open form
	const handleClose = () => {
		setUpdatingStatus(false);
		setUpdatingError(null);
		callback();
	};
	//handle show/hide password
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	}
	//handle mail submitting error
	const [updatingError, setUpdatingError] = useState(null)
	useSetError(updatingError);
	useEffect(() => {
		if (updatingError) {
			handleClose()
		}
	}, [updatingError])
	//get method to update snack notification
	const { createSnackNotification } = useSetSnackNotification();
	//handle positive submission
	const [updatingStatus, setUpdatingStatus] = useState(false);
	useEffect(() => {
		if (updatingStatus) {
			const UpdateFeedback = ({ }) => {
				return (
					<Typography variant="14px-reg">Your profile has been updated successfully!</Typography>
				)
			}
			const newUpdate = <UpdateFeedback />;
			createSnackNotification({
				type: "info-2",
				component: newUpdate
			});
			handleClose()
		}
	}, [updatingStatus])
	//data condition
	const showForm = userInfo;
	//render
	return (
		<>
			<ModalContent
				actionType="none"
				title={"Update your Profile"}
			>
				<Formik
					initialValues={{
						firstName: userInfo ? userInfo.first_name : "...",
						lastName: userInfo ? userInfo.last_name : "...",
						email: userInfo ? userInfo.email : "...",
						password: "",
						confirmPassword: "",
						submit: false,
					}}
					validationSchema={Yup.object().shape({
						firstName: Yup.string().max(255).required("First name is required"),
						lastName: Yup.string().max(255).required("Last name is required"),
						email: Yup.string()
							.email("Must be a valid email")
							.max(255)
							.required("Email is required"),
						password: Yup.string()
							.min(8, 'Password must be at least 8 characters')
							.matches(/[a-z]/, 'Password must contain at least one lowercase character')
							.matches(/[A-Z]/, 'Password must contain at least one uppercase character')
							.matches(/[0-9]/, 'Password must contain at least one number'),
						confirmPassword: Yup.string().when("password", {
							is: (val) => (val && val.length > 0 ? true : false),
							then: Yup.string().oneOf(
								[Yup.ref("password")],
								"Both password need to be the same"
							).required("Confirm password is required"),
						}),
					})}
					onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
						let body = {
							email: values.email,
							first_name: values.firstName,
							last_name: values.lastName
						};
						if (values.password) {
							body.password = values.password
						}
						try {
							const opt = {
								method: "POST",
								endpoint: `/api/userUpdate`,
								endpointCallLocation: "Profile index.js",
								body: body
							}
							const res = await fetchApiRoute(opt);
							resetForm();
							setStatus({ success: true });
							setSubmitting(false);
							setUpdatingStatus(true);
							mutate(["/api/user", "useGetUser"]);
						} catch (error) {
							setStatus({ success: false });
							setErrors({ submit: error });
							setSubmitting(false);
							setUpdatingError(error);
							setUpdatingStatus(false);
						}
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						touched,
						values,
						status
					}) => {
						const disableSend = (isSubmitting || Object.keys(errors).length !== 0);
						return (
							<>
								{
									(!showForm || isSubmitting) && <LinearProgress m={2} />
								}
								{
									showForm && !isSubmitting && !status &&
									<Grid item>
										<form noValidate onSubmit={handleSubmit}>
											{
												userInfo && testCompany &&
												<Grid container flexDirection mb={"0.75rem"}>
													<InputWithCustomLabel label="Experimental features" enableMinWidth={false} minWidth="fit-content" flexGrow={0} >
														<CustomSwitch
															checked={!simulateGenericUser}
															onChange={handleSimulateGenericUserState}
														/>
													</InputWithCustomLabel>
												</Grid>
											}
											<Grid container flexDirection={"column"} rowGap={"1.75rem"}>
												<Divider />
												<TextField
													type="text"
													name="firstName"
													label="First name"
													value={values.firstName}
													error={Boolean(touched.firstName && errors.firstName)}
													fullWidth
													helperText={touched.firstName && errors.firstName}
													onBlur={handleBlur}
													onChange={handleChange}
													disabled={isSubmitting}
												/>
												<TextField
													type="text"
													name="lastName"
													label="Last name"
													value={values.lastName}
													error={Boolean(touched.lastName && errors.lastName)}
													fullWidth
													helperText={touched.lastName && errors.lastName}
													onBlur={handleBlur}
													onChange={handleChange}
													disabled={isSubmitting}
												/>
												<TextField
													type="email"
													name="email"
													label="Email address"
													value={values.email}
													error={Boolean(touched.email && errors.email)}
													fullWidth
													helperText={touched.email && errors.email}
													onBlur={handleBlur}
													onChange={handleChange}
													disabled={isSubmitting}
												/>
												<FormControl
													fullWidth
													variant="outlined"
													error={Boolean(touched.password && errors.password)}
												>
													<InputLabel htmlFor="password">Password</InputLabel>
													<OutlinedInput
														id="password"
														name="password"
														label="Password"
														type={showPassword ? 'text' : 'password'}
														value={values.password}
														onChange={handleChange}
														onBlur={handleBlur}
														disabled={isSubmitting}
														endAdornment={
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={handleClickShowPassword}
																	onMouseDown={handleMouseDownPassword}
																	edge="end"
																>
																	{showPassword ? <VisibilityOff /> : <Visibility />}
																</IconButton>
															</InputAdornment>
														}
													/>
													{
														touched.password &&
														<FormHelperText id="component-error-text">{touched.password}</FormHelperText>
													}
													{
														errors.password &&
														<FormHelperText id="component-error-text">{errors.password}</FormHelperText>
													}
												</FormControl>
												<TextField
													type={showPassword ? 'text' : 'password'}
													name="confirmPassword"
													label="Confirm password"
													value={values.confirmPassword}
													error={Boolean(touched.confirmPassword && errors.confirmPassword)}
													fullWidth
													helperText={touched.confirmPassword && errors.confirmPassword}
													onBlur={handleBlur}
													onChange={handleChange}
													disabled={isSubmitting}
												/>
											</Grid>
										</form>
									</Grid>
								}
								<Grid container flexDirection={"row"} justifyContent={"center"} mt={"1.125rem"}>
									<Button disabled={disableSend || isSubmitting || status} onClick={handleSubmit} variant="modal-primary">
										Update
									</Button>
								</Grid>
							</>
						)
					}}
				</Formik>
			</ModalContent>
		</>
	);
}

export default Profile;
