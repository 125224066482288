//react
import React from "react";
//mui components
import Grid from '@mui/material/Grid';

const ArrowSvg = ({ onClick, ...props }) => {
	const sx = {};
	if (onClick) {
		sx.cursor = "pointer"
	}
	return (
		<Grid container sx={sx} onClick={onClick}>
			<svg width="43" height="9" viewBox="0 0 43 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M42.3536 4.85355C42.5488 4.65829 42.5488 4.34171 42.3536 4.14644L39.1716 0.964463C38.9763 0.7692 38.6597 0.769201 38.4645 0.964463C38.2692 1.15972 38.2692 1.47631 38.4645 1.67157L41.2929 4.5L38.4645 7.32842C38.2692 7.52369 38.2692 7.84027 38.4645 8.03553C38.6597 8.23079 38.9763 8.23079 39.1716 8.03553L42.3536 4.85355ZM4.37114e-08 5L42 5L42 4L-4.37114e-08 4L4.37114e-08 5Z" fill="#5A5877" />
			</svg>
		</Grid>

	);
}

export default ArrowSvg;