//react
import React from "react";
//mui components
import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
//sqlitecloud hooks
import { useGetProjectInfo } from "@custom-hooks/useApi";
//sqlitecloud components
import Card from "@generic-components/Card";
import ModalContent from "@generic-components/ModalContent";
import CopyToClipboardButton from "@generic-components/CopyToClipboardButton";

const SingleProjectInfo = ({ projectId }) => {
	//get theme
	const theme = useTheme();
	//get acutal project info
	const { deployment, connectionStringApiKey, adminApiKey, showLoader } = useGetProjectInfo(projectId);
	//render
	return (
		<ModalContent
			title={"Project Info"}
			showLoader={showLoader}
		>
			{
				!showLoader && deployment && connectionStringApiKey &&
				<Card customSx={{ padding: "1rem 1rem 0.5rem 1rem" }}>
					<Box>
						<Typography as="div" variant="17px-light" align="left" sx={{ color: theme.palette.secondary.accent3 }}>
							Deployment
						</Typography>
						<CopyToClipboardButton label={deployment ? deployment : "..."} value={deployment ? deployment : "..."} alignLeft={true} />
					</Box>
					{/* <Box sx={{ mt: 3 }}>
						<Typography as="div" variant="17px-light" align="left" sx={{ color: theme.palette.secondary.accent3 }}>
							Admin API key
						</Typography>
						<CopyToClipboardButton label={adminApiKey ? adminApiKey : "..."} value={adminApiKey ? adminApiKey : "..."} alignLeft={true} />
					</Box> */}
					<Box sx={{ mt: 3 }}>
						<Typography as="div" variant="17px-light" align="left" sx={{ color: theme.palette.secondary.accent3 }}>
							Connection String
						</Typography>
						<CopyToClipboardButton label={connectionStringApiKey ? connectionStringApiKey : "..."} value={connectionStringApiKey ? connectionStringApiKey : "..."} alignLeft={true} />
					</Box>
				</Card>
			}
		</ModalContent>
	);
};

export default SingleProjectInfo;