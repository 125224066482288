//react
import React from "react";

const MegaphoneSVG = ({ width = "36", height = "36", ...props }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_7_2" style={{ "maskType": "alpha" }} maskUnits="userSpaceOnUse" x="1" y="2" width="35" height="34">
				<rect x="1" y="2.30701" width="34.5978" height="33.693" rx="16.8465" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_7_2)">
				<path d="M1 7.93982L35.5978 31.5447" stroke="black" strokeWidth="0.5" />
				<path d="M1 7.0968L35.5978 30.7017" stroke="black" strokeWidth="0.5" />
				<path d="M1 6.25378L35.5978 29.8587" stroke="black" strokeWidth="0.5" />
				<path d="M1 5.41071L35.5978 29.0156" stroke="black" strokeWidth="0.5" />
				<path d="M1 4.56775L35.5978 28.1726" stroke="black" strokeWidth="0.5" />
				<path d="M1 3.72455L35.5978 27.3294" stroke="black" strokeWidth="0.5" />
				<path d="M1 2.88153L35.5978 26.4864" stroke="black" strokeWidth="0.5" />
				<path d="M1 2.03857L35.5978 25.6435" stroke="black" strokeWidth="0.5" />
				<path d="M1 1.19568L35.5978 24.8006" stroke="black" strokeWidth="0.5" />
				<path d="M1 0.352478L35.5978 23.9574" stroke="black" strokeWidth="0.5" />
				<path d="M1 -0.490601L35.5978 23.1143" stroke="black" strokeWidth="0.5" />
				<path d="M1 -1.33356L35.5978 22.2713" stroke="black" strokeWidth="0.5" />
				<path d="M1 -2.17651L35.5978 21.4284" stroke="black" strokeWidth="0.5" />
				<path d="M1 -3.01959L35.5978 20.5853" stroke="black" strokeWidth="0.5" />
				<path d="M1 -3.86267L35.5978 19.7422" stroke="black" strokeWidth="0.5" />
				<path d="M1 -4.70563L35.5978 18.8993" stroke="black" strokeWidth="0.5" />
				<path d="M1 -5.54865L35.5978 18.0562" stroke="black" strokeWidth="0.5" />
				<path d="M1 -6.39178L35.5978 17.2131" stroke="black" strokeWidth="0.5" />
				<path d="M1 -7.23468L35.5978 16.3702" stroke="black" strokeWidth="0.5" />
				<path d="M1 -8.0777L35.5978 15.5272" stroke="black" strokeWidth="0.5" />
				<path d="M1 -8.92084L35.5978 14.684" stroke="black" strokeWidth="0.5" />
				<path d="M1 -9.76385L35.5978 13.841" stroke="black" strokeWidth="0.5" />
				<path d="M1 -10.607L35.5978 12.9979" stroke="black" strokeWidth="0.5" />
				<path d="M1 -11.45L35.5978 12.1549" stroke="black" strokeWidth="0.5" />
				<path d="M1 -12.293L35.5978 11.3119" stroke="black" strokeWidth="0.5" />
				<path d="M1 -13.1359L35.5978 10.469" stroke="black" strokeWidth="0.5" />
				<path d="M1 -13.9789L35.5978 9.626" stroke="black" strokeWidth="0.5" />
				<path d="M1 -14.822L35.5978 8.78286" stroke="black" strokeWidth="0.5" />
				<path d="M1 -15.6652L35.5978 7.93972" stroke="black" strokeWidth="0.5" />
				<path d="M1 -16.4846L35.5978 7.12026" stroke="black" strokeWidth="0.5" />
				<path d="M1 -17.304L35.5978 6.30086" stroke="black" strokeWidth="0.5" />
				<path d="M1 -18.1235L35.5978 5.48141" stroke="black" strokeWidth="0.5" />
				<path d="M1 -18.9431L35.5978 4.66183" stroke="black" strokeWidth="0.5" />
				<path d="M1 -19.7625L35.5978 3.84237" stroke="black" strokeWidth="0.5" />
				<path d="M1 8.78278L35.5978 32.3877" stroke="black" strokeWidth="0.5" />
				<path d="M1 9.62585L35.5978 33.2307" stroke="black" strokeWidth="0.5" />
				<path d="M1 10.4689L35.5978 34.0738" stroke="black" strokeWidth="0.5" />
				<path d="M1 11.312L35.5978 34.9168" stroke="black" strokeWidth="0.5" />
				<path d="M1 12.1549L35.5978 35.7598" stroke="black" strokeWidth="0.5" />
				<path d="M1 12.9981L35.5978 36.603" stroke="black" strokeWidth="0.5" />
				<path d="M1 13.8409L35.5978 37.4458" stroke="black" strokeWidth="0.5" />
				<path d="M1 14.6839L35.5978 38.2888" stroke="black" strokeWidth="0.5" />
				<path d="M1 15.527L35.5978 39.1319" stroke="black" strokeWidth="0.5" />
				<path d="M1 16.3702L35.5978 39.9751" stroke="black" strokeWidth="0.5" />
				<path d="M1 17.2131L35.5978 40.818" stroke="black" strokeWidth="0.5" />
				<path d="M1 18.0562L35.5978 41.661" stroke="black" strokeWidth="0.5" />
				<path d="M1 18.899L35.5978 42.5039" stroke="black" strokeWidth="0.5" />
				<path d="M1 19.7422L35.5978 43.3471" stroke="black" strokeWidth="0.5" />
				<path d="M1 20.5854L35.5978 44.1903" stroke="black" strokeWidth="0.5" />
				<path d="M1 21.4283L35.5978 45.0332" stroke="black" strokeWidth="0.5" />
				<path d="M1 22.2713L35.5978 45.8762" stroke="black" strokeWidth="0.5" />
				<path d="M1 23.1143L35.5978 46.7192" stroke="black" strokeWidth="0.5" />
				<path d="M1 23.9573L35.5978 47.5622" stroke="black" strokeWidth="0.5" />
				<path d="M1 24.8004L35.5978 48.4052" stroke="black" strokeWidth="0.5" />
				<path d="M1 25.6434L35.5978 49.2483" stroke="black" strokeWidth="0.5" />
				<path d="M1 26.4866L35.5978 50.0915" stroke="black" strokeWidth="0.5" />
				<path d="M1 27.3295L35.5978 50.9344" stroke="black" strokeWidth="0.5" />
				<path d="M1 28.1726L35.5978 51.7775" stroke="black" strokeWidth="0.5" />
				<path d="M1 29.0156L35.5978 52.6204" stroke="black" strokeWidth="0.5" />
				<path d="M1 29.8587L35.5978 53.4636" stroke="black" strokeWidth="0.5" />
				<path d="M1 30.7017L35.5978 54.3065" stroke="black" strokeWidth="0.5" />
				<path d="M1 31.5447L35.5978 55.1496" stroke="black" strokeWidth="0.5" />
				<path d="M1 32.3879L35.5978 55.9928" stroke="black" strokeWidth="0.5" />
				<path d="M1 33.2308L35.5978 56.8357" stroke="black" strokeWidth="0.5" />
				<path d="M1 34.0737L35.5978 57.6786" stroke="black" strokeWidth="0.5" />
				<path d="M1 34.9167L35.5978 58.5216" stroke="black" strokeWidth="0.5" />
				<path d="M1 35.76L35.5978 59.3649" stroke="black" strokeWidth="0.5" />
			</g>
			<g clipPath="url(#clip0_7_2)">
				<rect x="1" y="1" width="32.9886" height="31.7817" rx="15.8908" fill="white" />
				<circle cx="23" cy="22" r="20" fill="#C3D7F2" />
				<path d="M24.3997 22.298L24.3997 22.2982C24.3074 23.182 23.2722 23.6428 22.5403 23.1031L22.5401 23.103L18.5798 20.1858C17.2451 19.2026 15.6269 18.672 13.9648 18.6719H13.9648H10.268C10.1431 18.6719 10.0243 18.6227 9.93738 18.5366C9.85063 18.4506 9.80273 18.335 9.80273 18.2154V14.2308C9.80273 14.1113 9.85063 13.9957 9.93738 13.9097C10.0243 13.8236 10.1431 13.7744 10.268 13.7744H13.9629H13.9629C15.6254 13.7742 17.244 13.2432 18.5788 12.2595C18.5788 12.2595 18.5789 12.2595 18.5789 12.2594L22.5391 9.34236C23.2714 8.80304 24.3075 9.26472 24.3997 10.1472L24.3997 10.1473C24.8237 14.1871 24.8237 18.2582 24.3997 22.298Z" fill="white" stroke="black" />
				<path d="M14.3309 21.1691L14.3308 21.1691L13.3986 24.5251C13.3986 24.5251 13.3986 24.5252 13.3986 24.5252C13.3897 24.5571 13.3717 24.5904 13.3422 24.6211C13.3125 24.652 13.2721 24.6791 13.2226 24.6969C13.173 24.7147 13.1181 24.7219 13.063 24.7167C13.008 24.7115 12.9573 24.6944 12.9152 24.6693L12.915 24.6691L10.6183 23.2969C10.6182 23.2968 10.6182 23.2968 10.6181 23.2967C10.5532 23.2578 10.5164 23.2048 10.502 23.1558C10.4879 23.1081 10.4924 23.0568 10.5188 23.0082C10.5188 23.0082 10.5188 23.0081 10.5188 23.0081L11.5952 21.0247L11.5954 21.0244C11.6159 20.9866 11.6506 20.949 11.7008 20.9204C11.7511 20.8917 11.8124 20.8748 11.8774 20.8746L14.03 20.8746L14.0303 20.8746C14.0836 20.8746 14.1348 20.8859 14.1798 20.9061C14.2247 20.9264 14.2602 20.9541 14.2858 20.9845C14.3112 21.0146 14.3263 21.0466 14.3332 21.0771C14.34 21.1075 14.3393 21.1386 14.3309 21.1691Z" fill="white" stroke="black" />
			</g>
			<rect x="0.5" y="0.5" width="33.9886" height="32.7817" rx="16.3908" stroke="#1F1F1F" strokeOpacity="0.6" />
			<defs>
				<clipPath id="clip0_7_2">
					<rect x="1" y="1" width="32.9886" height="31.7817" rx="15.8908" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default MegaphoneSVG;