//react
import React from "react";
//sqlitecloud components
import SidebarNavList from "./SidebarNavList";

const SidebarNavSection = (props) => {
	const {
		title,
		pages,
		className,
		isMobile,
		hideSideBarOnDesk,
		isProjectsHome,
		component: Component = "nav",
		...rest
	} = props;
	//render
	return (
		<Component {...rest}>
			<SidebarNavList pages={pages} depth={0} isProjectsHome={isProjectsHome} isMobile={isMobile} hideSideBarOnDesk={hideSideBarOnDesk}/>
		</Component >
	);
};

export default SidebarNavSection;