//react component
import React from "react";
//next.js
import { useRouter } from 'next/router'
//mui components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//sqlitecloud components¯
import DocsSvg from "@components/GenericElements/SVG/DocsSvg";
//sqlitecloud libs
import { Documentation } from "@lib/icons";

function Docs({ isMobile = false }) {
	const router = useRouter();
	let pathnameEnd = router.pathname.split('/').pop();

	switch (pathnameEnd) {
		case "nodes":
			pathnameEnd = "connect-cluster";
			break;
		case "databases":
			pathnameEnd = "create-database";
			break;
		case "tables":
			pathnameEnd = "write-data";
			break;
		case "query-analyzer":
		case "details":
			pathnameEnd = "analyzer";
			break;
		case "plugins":
			pathnameEnd = "plugin-commands";
			break;
		case "console":
		case "commands":
			pathnameEnd = "server-side-commands";
			break;
		case "api-keys":
		case "ip-restriction":
		case "users":
		case "roles":
			pathnameEnd = `security#${pathnameEnd}`;
			break;
		case "advanced-settings":
			pathnameEnd = "settings";
			break;
		case "":
			if (router.pathname == "/") {
				pathnameEnd = "sqlite-cloud";
			}
			break;
		case "logs":
		case "environment-variables":
			pathnameEnd = "sqlite-cloud";
			break;
	}

	const docsClick = () => window.open('https://docs.sqlitecloud.io/docs/' + pathnameEnd, '_blank')
	if (isMobile) {
		return (
			<IconButton
				sx={{ padding: 0 }}
				onClick={docsClick}
				color="primary"
				disableFocusRipple={true}
				disableRipple={true}
			>
				<Box padding={"0.75rem 1.75rem 0.75rem 1rem"} justifyContent={"flex-start"} alignItems={"center"} gap={"0.5rem"} display={"inline-flex"}>
					<Documentation />
					<Typography fontFamily={"SwissIntl"} variant="14px-reg" fontWeight={"450"}>Documentation</Typography>
				</Box>
			</IconButton>
		);
	}
	return (
		<Tooltip title="Documentation">
			<span>
				<IconButton
					sx={{ padding: 0 }}
					onClick={docsClick}
					color="primary"
					disableFocusRipple={true}
					disableRipple={true}
				>
					<DocsSvg />
				</IconButton>
			</span>
		</Tooltip>
	);
}

export default Docs;
