function HelpSvg() {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_980_9046)">
				<path d="M10.0001 18.6242C14.7631 18.6242 18.6242 14.7631 18.6242 10.0001C18.6242 5.23712 14.7631 1.37598 10.0001 1.37598C5.23712 1.37598 1.37598 5.23712 1.37598 10.0001C1.37598 14.7631 5.23712 18.6242 10.0001 18.6242Z" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M7.67822 7.67783C7.67822 7.2186 7.81452 6.76969 8.06989 6.38786C8.32524 6.00604 8.68821 5.70844 9.11286 5.53269C9.53751 5.35696 10.0048 5.31099 10.4556 5.40057C10.9064 5.49016 11.3205 5.71129 11.6455 6.03602C11.9705 6.36074 12.1919 6.77446 12.2815 7.22485C12.3712 7.67525 12.3252 8.14209 12.1493 8.56636C11.9734 8.99063 11.6755 9.35325 11.2933 9.60838C10.9112 9.86351 10.4618 9.99969 10.0022 9.99969V11.5476" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M10.0002 14.5887C9.81696 14.5887 9.66846 14.4402 9.66846 14.257C9.66846 14.0738 9.81696 13.9253 10.0002 13.9253" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M10 14.5887C10.1832 14.5887 10.3317 14.4402 10.3317 14.257C10.3317 14.0738 10.1832 13.9253 10 13.9253" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
			<defs>
				<clipPath id="clip0_980_9046">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default HelpSvg;