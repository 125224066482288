//react
import React from "react";
//mui components
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
//sqlitecloud components
import Card from "@generic-components/Card";
import ContactSupportAction from "@generic-components/ContactSupportAction";


const ModalTitle = ({ title = undefined, subtitle = undefined, subtitleSmall = undefined, description = undefined, contact = false, contactText = undefined, feedbackFormContent = {} }) => {
	const showCard = (subtitle || subtitleSmall || description || contact);
	//render
	return (
		<Grid item sx={{ minHeight: "2.625rem" }}>
			<Grid container flexDirection={"column"} rowGap={"1.125rem"}>
				<Grid item width={"100%"}>
					<Typography
						as="div"
						variant="h4-desk"
						align="left"
						gutterBottom={false}
						sx={{
							// "whiteSpace": "nowrap",
							"overflow": "hidden",
							"textOverflow": "ellipsis",
							"maxWidth": "100%",
						}}
					>
						{title}
					</Typography>
				</Grid>
				{
					showCard &&
					<Card type={"info-1"}>
						<Grid container flexDirection={"column"} rowGap={1}>
							{
								subtitle &&
								<Typography as="div" variant="17px-book" align="left" gutterBottom={description ? true : false}>
									{subtitle}<br />
								</Typography>
							}
							{
								subtitleSmall &&
								<Typography as="div" variant="14px-reg" align="left" gutterBottom={description ? true : false}>
									{
										Array.isArray(subtitleSmall) &&
										<>
											{
												subtitleSmall.map((line, i) => (
													<span key={i} style={{ display: "block", paddingBottom: "2px" }}>
														{line}
													</span>
												))
											}
										</>
									}
									{
										!Array.isArray(subtitleSmall) &&
										<>
											{subtitleSmall}
										</>
									}
								</Typography>
							}
							{
								description &&
								<Typography gutterBottom={true} as="div" variant="14px-light">
									{
										description.map((line, i) => (
											<span key={i} style={{ display: "block" }}>
												{line}
											</span>
										))
									}
								</Typography>
							}
							{
								contact &&
								<ContactSupportAction text={contactText} feedbackFormContent={feedbackFormContent} />
							}
						</Grid>
					</Card>
				}
			</Grid>
		</Grid>
	);
}

export default ModalTitle;
