function BellSvg() {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_980_9040)">
				<path d="M14.9757 8.0099C16.8075 8.0099 18.2926 6.52485 18.2926 4.69294C18.2926 2.86103 16.8075 1.37598 14.9757 1.37598C13.1437 1.37598 11.6587 2.86103 11.6587 4.69294C11.6587 6.52485 13.1437 8.0099 14.9757 8.0099Z" fill="#CFE2F8" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M13.8911 11.3269V13.3616C13.8911 13.8806 14.0972 14.3783 14.4642 14.7453C14.831 15.1121 15.4517 15.3183 15.9706 15.3183H1.37598C1.89491 15.3183 2.51553 15.1121 2.88248 14.7453C3.24941 14.3783 3.45557 13.8806 3.45557 13.3616L3.45555 8.58395C3.45555 7.20013 4.00528 5.87297 4.9838 4.89446C5.96232 3.91594 7.28947 3.36621 8.6733 3.36621" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M7.34668 18.6243H10.0003" stroke="#0D0C22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
			<defs>
				<clipPath id="clip0_980_9040">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>

	);
}

export default BellSvg;