//backups allowed retention values
const retentionsValue = [
	{
		name: "1 day",
		value: "24h"
	},
	{
		name: "1 week",
		value: "168h"
	},
	{
		name: "1 month",
		value: "720h"
	},
];

function findRetentionName(value) {
	if (value) {
		const retention = retentionsValue.find(retention => retention.value === value);
		return retention ? retention.name : "...";
	} else {
		return "..."
	}
}


//check if a database name exists into databases list
const databaseNameExist = (databaseName, databasesList) => {
	let test = false;
	if (databasesList) {
		databasesList.forEach((database) => {
			if (database.name === databaseName) {
				test = true;
			}
		});
	}
	return test;
}

//check if a table name exists into tables list
const tableNameExist = (tableName, tablesList) => {
	let test = false;
	if (tablesList) {
		tablesList.forEach((table) => {
			if (table.name === tableName) {
				test = true;
			}
		});
	}
	return test;
}

//check if a node id exists into nodes list
const nodeIdExist = (nodeId, nodesList) => {
	let test = false;
	if (nodesList) {
		nodesList.forEach((node) => {
			if (node.node_id == nodeId) {
				test = true;
			}
		});
	}
	return test;
}


//check if a node id exists into nodes list
const nodeIsWorking = (node) => {
	const isWorking = node && node.match_leader > 0 ? true : false
	return isWorking;
}

//get node UUID
const getNodeUUID = (node) => {
	if (node && node.address) {
		const address = node.address;
		let firstPointIndex = address.indexOf('.');
		let uuid = address.substring(0, firstPointIndex);
		return uuid;
	} else {
		return "unknown"
	}
}


//get node location
const getNodeLocation = (node) => {
	if (node && node.region) {
		const region = node.region;
		const regex = /\((.*?)\)/;
		const matches = region.match(regex);
		if (matches) {
			const location = matches[1];
			return location;
		} else {
			return "unknown"
		}
	} else {
		return "unknown"
	}
}

//extract job status
const getJobStatus = (job) => {
	let status = "";
	if (job.error == 1) {
		status = "error";
	} else if (job.error == 0) {
		if (job.progress < job.steps) {
			status = "progress";
		}
		if (job.steps == job.progress) {
			status = "completed";
		}
	}
	return status;
}

//return Select Input style
const computeInputSelectStyle = (styleVariant, label) => {
	let variant = "outlined";
	let className = "";
	let textFieldSx = {};
	let selectSx = {};
	switch (styleVariant) {
		case 0:
			variant = "outlined";
			textFieldSx = {
				width: "100%",
				mt: 2
			}
			selectSx = {
				width: "100%",
			}
			break;
		case 1:
			variant = "outlined";
			selectSx = {
				width: "16.5rem",
			}
			break;
		case 2:
			variant = "standard";
			className = "console";
			textFieldSx = {
				minWidth: "fit-content",
				padding: "0 1rem",
				borderRadius: 0,
				borderTopLeftRadius: "8px",
				borderRight: " #5A5877 solid 1px",
			}
			selectSx = {
				boxSizing: "content-box",
				background: "black",
				color: "white",
				fontFamily: "Roboto Mono",
				width: "8rem",
				height: "100%",
				color: "#F0F5FD"
			}
			label = undefined;
			break;
		case 3:
			variant = "outlined";
			textFieldSx = {
				width: "100%",
			}
			selectSx = {
				width: "100%",
			}
			break;
		default:
			variant = "outlined";
			selectSx = {
				width: "16.5rem",
			}
			break;
	}

	return {
		variant,
		textFieldSx,
		selectSx,
		label,
		className
	}
}

//convert database status number to color and label
const convertDatabaseStatus = (status, theme) => {
	let databaseStatus;
	switch (status) {
		case 0:
			databaseStatus = {
				tooltip: "File is not a valid SQLite database. If database is encrypted, provide an encryption by using the action menu.",
				color: theme.palette.neutral.lightGrey
			};
			break;
		case 1:
			databaseStatus = {
				tooltip: "Database works as expected.",
				color: theme.palette.success.main
			};
			break;
		case 2:
			databaseStatus = {
				tooltip: "Database is disabled.",
				color: theme.palette.neutral.lightGrey
			};
			break;
		case 3:
			databaseStatus = {
				tooltip: "Database in maintenance mode.",
				color: theme.palette.warning.main
			};
			break;
		case 4:
			databaseStatus = {
				tooltip: "Database is in error state. If database is encrypted, provide an encryption by using the action menu.",
				color: theme.palette.error.main
			};
			break;
		default:
			databaseStatus = {
				tooltip: "UNKNOWN",
				color: theme.palette.neutral.lightGrey
			};
			break;
	}
	databaseStatus.value = status;
	return databaseStatus;
};

//convert metadata key number to color and label
const convertMetadataKeyStatus = (status, theme) => {
	let primaryKeyStatus;
	switch (status) {
		case 0:
			primaryKeyStatus = {
				tooltip: "",
				color: theme.palette.neutral.lightGrey
			};
			break;
		case 1:
			primaryKeyStatus = {
				tooltip: "Primary Key.",
				color: theme.palette.success.main
			};
			break;
		default:
			primaryKeyStatus = {
				tooltip: "",
				color: theme.palette.neutral.lightGrey
			};
			break;
	}
	return primaryKeyStatus;
};


//associate to node type color, tooltip and others
const styleNodeBasedOnType = (node, theme) => {
	const nodeType = node.type;
	const nodeMatchLeader = node.match_leader;
	let nodeIndicator;
	if (!nodeMatchLeader) {
		nodeType = "not working"
	} else {
		if (nodeType) {
			nodeType = nodeType.toLowerCase();
		} else {
			nodeType = "undefined"
		}
	}
	switch (nodeType) {
		case 'not working':
			nodeIndicator = { color: theme.palette.error.light, type: "Node isn't working." };
			break;
		case 'leader':
			nodeIndicator = { color: theme.palette.success.main, type: "Node is leader." };
			break;
		case 'follower':
			nodeIndicator = { color: theme.palette.secondary.main, type: "Node is a follower." };
			break;
		case 'learner':
			nodeIndicator = { color: theme.palette.info.light, type: "Node is a learner." };
			break;
		case 'undefined':
			nodeIndicator = { color: theme.palette.info.light, type: "Node type not defined." };
			break;
		default:
			nodeIndicator = { color: theme.palette.error.light, type: "Node type not defined." };
	}
	return nodeIndicator;
}

//convert log.type integer value to string
const convertLogTypeId = (logTypeId) => {
	let logType;
	switch (logTypeId) {
		case 1:
			logType = "INTERNAL";
			break;
		case 2:
			logType = "SECURITY";
			break;
		case 3:
			logType = "SQL";
			break;
		case 4:
			logType = "COMMAND";
			break;
		case 5:
			logType = "RAFT";
			break;
		case 6:
			logType = "CLUSTER";
			break;
		case 7:
			logType = "PLUGIN";
			break;
		case 8:
			logType = "CLIENT";
			break;
		default:
			logType = "undefined";
			break;
	}
	return logType;
}

//convert log.level integer value to string
const convertLogLevelId = (logLevelId) => {
	let logLevel;
	switch (logLevelId) {
		case 0:
			logLevel = "PANIC";
			break;
		case 1:
			logLevel = "FATAL";
			break;
		case 2:
			logLevel = "ERROR";
			break;
		case 3:
			logLevel = "WARNING";
			break;
		case 4:
			logLevel = "INFO";
			break;
		case 5:
			logLevel = "DEBUG";
			break;
		default:
			logLevel = "undefined";
			break;
	}
	return logLevel;
}


export {
	retentionsValue,
	findRetentionName,
	databaseNameExist,
	tableNameExist,
	nodeIdExist,
	nodeIsWorking,
	getNodeUUID,
	getNodeLocation,
	getJobStatus,
	computeInputSelectStyle,
	styleNodeBasedOnType,
	convertDatabaseStatus,
	convertMetadataKeyStatus,
	convertLogTypeId,
	convertLogLevelId
}