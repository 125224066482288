function CloseNavSVG() {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20" cy="20" r="20" fill="#F7F9FC" />
			<path fillRule="evenodd" clipRule="evenodd" d="M13.2272 13.2272C13.3728 13.0817 13.5702 13 13.7761 13C13.9819 13 14.1793 13.0817 14.325 13.2272L26.7528 25.655C26.8291 25.7261 26.8903 25.8119 26.9328 25.9071C26.9752 26.0024 26.998 26.1053 26.9999 26.2096C27.0017 26.3139 26.9825 26.4174 26.9435 26.5142C26.9044 26.6109 26.8463 26.6987 26.7725 26.7725C26.6987 26.8463 26.6109 26.9044 26.5142 26.9435C26.4174 26.9825 26.3139 27.0017 26.2096 26.9999C26.1053 26.998 26.0024 26.9752 25.9071 26.9328C25.8119 26.8903 25.7261 26.8291 25.655 26.7528L13.2272 14.325C13.0817 14.1793 13 13.9819 13 13.7761C13 13.5702 13.0817 13.3728 13.2272 13.2272Z" fill="black" />
			<path fillRule="evenodd" clipRule="evenodd" d="M26.7526 13.2272C26.8981 13.3728 26.9798 13.5702 26.9798 13.7761C26.9798 13.9819 26.8981 14.1793 26.7526 14.325L14.3248 26.7528C14.1776 26.89 13.9828 26.9647 13.7816 26.9611C13.5804 26.9576 13.3884 26.8761 13.2461 26.7337C13.1037 26.5914 13.0222 26.3994 13.0187 26.1982C13.0151 25.997 13.0898 25.8022 13.227 25.655L25.6549 13.2272C25.8005 13.0817 25.9979 13 26.2037 13C26.4096 13 26.607 13.0817 26.7526 13.2272Z" fill="black" />
		</svg>

	);
}

export default CloseNavSVG;